// contracts.ts
import { ethers } from 'ethers';

import ProtoniaTokenABI from './abis/ProtoniaToken.json';
import PublicSaleABI from './abis/PublicSale.json';
import CharacterManagementABI from './abis/CharacterManagement.json';
import LeaderBoardABI from './abis/LeaderBoard.json';
import DuelContractABI from './abis/DuelContract.json';
import ItemManagementABI from './abis/ItemManagement.json';
import AchievementManagementABI from './abis/AchievementManagement.json';
import ReferralManagementABI from './abis/ReferralManagement.json';
import MarketplaceABI from './abis/Marketplace.json';
import EventManagementABI from './abis/EventManagement.json';
import ChatManagementABI from './abis/ChatManagement.json';
import ClanManagementABI from './abis/ClanManagement.json';
import AdvancedGameABI from './abis/AdvancedGame.json';
import EvolutionManagementABI from './abis/EvolutionManagement.json';
import PvEManagementABI from './abis/PvEManagement.json';
import SpaceShipManagerABI from './abis/SpaceShipManager.json';
import StakingManagerABI from './abis/StakingManager.json';
import TalentManagementABI from './abis/TalentManagement.json';
import AnnouncementBoardABI from './abis/AnnouncementBoard.json';
import CharacterDataABI from './abis/CharacterData.json';

export const ContractAddresses = {
  ClanManagement: '0x53BC8C3AfD80f9267ada591376c84e9876835D46',
  ChatManagement: '0xea0D8413902e412497cD6800A93ab188376f4146',
  AnnouncementBoard: '0x8d5771Eeee117F4b7CAa9F25519008A38e37a47e',
  ProtoniaToken: '0x52941A594eb3f57ceB88717aa4190EEf5da10f6D',
  CharacterManagement: '0x7f21801F46139b6c0aa6840036BA5Fb6e23f7E26',
  LeaderBoard: '0x3Ce86278B6139DF947a46B9EBe9Dc1da152f4781',
  CharacterData: '0x4e2c9cd096AA33c656fE5384aFB7Fd492fD3f7F8',
  DuelContract: '0xC18AB061d26862f0a4eb84cF51660F222f60E999',
  AdvancedGame: '0x65f36C88153383AB94d1EB52DEb761712EdD19cd', 
  EvolutionManagement: '0xD9ae0846B05f190CaCBE66a94De4bF54fC56ab95',
  PvEManagement: '0xDE0C82B66F9E118B7c75f82CD147A47a1BbfE127',
  SpaceShipManager: '0xBa55DbA381F9e7644a97532d3996B011b37BcB52',
  StakingManager: '0x6CA5Bc7De89a70a70B92bF5eeCA814348CF3fe54',
  TalentManagement: '0x341bcA6612Eb5Baa7283c35fad6934158406688c',
  PublicSale: '0xA0d53953aa334B0999A648332a695361345922Ca', 
  ItemManagement: '0xf0D28BaDA72D4EA096a15F41555B119d111CfEFf',
  AchievementManagement: '0x1a1643590943F429519D3D9Cd693bC94196c3a21',
  ReferralManagement: '0x6a7fA4D8dfBaac2a93Db11799E88024C4fD726a3',
  Marketplace: '0x88bA52c09C397874DDEDbAcF4F86A2972bfc111A',
  EventManagement: '0x368A4117efe272A7D49F1b4722CB279A1a0DF1c2',
};



export const getContracts = (providerOrSigner: ethers.providers.Provider | ethers.Signer) => ({
  clanManagementContract: new ethers.Contract(ContractAddresses.ClanManagement, ClanManagementABI, providerOrSigner),
  characterDataContract: new ethers.Contract(ContractAddresses.CharacterData, CharacterDataABI, providerOrSigner),
  chatManagementContract: new ethers.Contract(ContractAddresses.ChatManagement, ChatManagementABI, providerOrSigner),
  announcementBoardContract: new ethers.Contract(ContractAddresses.AnnouncementBoard, AnnouncementBoardABI, providerOrSigner),
  protoniaTokenContract: new ethers.Contract(ContractAddresses.ProtoniaToken, ProtoniaTokenABI, providerOrSigner),
  publicSaleContract: new ethers.Contract(ContractAddresses.PublicSale, PublicSaleABI, providerOrSigner),
  characterManagementContract: new ethers.Contract(ContractAddresses.CharacterManagement, CharacterManagementABI, providerOrSigner),
  leaderBoardContract: new ethers.Contract(ContractAddresses.LeaderBoard, LeaderBoardABI, providerOrSigner),
  duelContract: new ethers.Contract(ContractAddresses.DuelContract, DuelContractABI, providerOrSigner),
  advancedGameContract: new ethers.Contract(ContractAddresses.AdvancedGame, AdvancedGameABI, providerOrSigner),
  evolutionManagementContract: new ethers.Contract(ContractAddresses.EvolutionManagement, EvolutionManagementABI, providerOrSigner),
  pveManagementContract: new ethers.Contract(ContractAddresses.PvEManagement, PvEManagementABI, providerOrSigner),
  spaceShipManagerContract: new ethers.Contract(ContractAddresses.SpaceShipManager, SpaceShipManagerABI, providerOrSigner),
  stakingManagerContract: new ethers.Contract(ContractAddresses.StakingManager, StakingManagerABI, providerOrSigner),
  talentManagementContract: new ethers.Contract(ContractAddresses.TalentManagement, TalentManagementABI, providerOrSigner),
  itemManagementContract: new ethers.Contract(ContractAddresses.ItemManagement, ItemManagementABI, providerOrSigner),
  achievementManagementContract: new ethers.Contract(ContractAddresses.AchievementManagement, AchievementManagementABI, providerOrSigner),
  referralManagementContract: new ethers.Contract(ContractAddresses.ReferralManagement, ReferralManagementABI, providerOrSigner),
  marketplaceContract: new ethers.Contract(ContractAddresses.Marketplace, MarketplaceABI, providerOrSigner),
  eventManagementContract: new ethers.Contract(ContractAddresses.EventManagement, EventManagementABI, providerOrSigner),
});

