import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ethers } from 'ethers';
import ClanDashboard from './ClanDashboard';
import '../styles/ClanManagement.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getContracts } from '../contracts'; 

interface ClanManagementProps {
  provider: ethers.providers.Web3Provider; // Тип провайдера остается Web3Provider
  account: string;
  clanCreationFee: string;
  registeredName: string;
  setRegisteredName: React.Dispatch<React.SetStateAction<string | null>>;
}

const ClanManagement: React.FC<ClanManagementProps> = ({
  provider,
  account,
  clanCreationFee,
  registeredName,
  setRegisteredName,
}) => {
  const signer = provider.getSigner();
  const { 
    clanManagementContract: baseClanManagementContract, 
    characterManagementContract, 
    chatManagementContract, 
    announcementBoardContract 
  } = getContracts(provider);

  const clanManagementContract = baseClanManagementContract.connect(signer);
  
  // Используем useRef для хранения состояния кэша
  const fetchCacheRef = useRef({
    invitations: null as { clanId: number; clanName: string }[] | null,
    allClans: null as { clanId: number; clanName: string; imageUrl: string }[] | null,
    clanId: null as number | null,
  });

  const [formData, setFormData] = useState({ clanName: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clanId, setClanId] = useState<number | null>(fetchCacheRef.current.clanId);
  const [invitations, setInvitations] = useState<{ clanId: number; clanName: string }[]>(fetchCacheRef.current.invitations || []);
  const [allClans, setAllClans] = useState<{ clanId: number; clanName: string; imageUrl: string }[]>(fetchCacheRef.current.allClans || []);

  const fetchInvitations = useCallback(async () => {
    if (fetchCacheRef.current.invitations) {
      setInvitations(fetchCacheRef.current.invitations);
      return;
    }

    if (!clanManagementContract) {
      console.error("Clan management contract is not available.");
      return;
    }
    try {
      const clanIds: ethers.BigNumber[] = await clanManagementContract.getClanInvitations(account);
      const invitationDetails = await Promise.all(
        clanIds.map(async (clanId: ethers.BigNumber) => {
          const [clanName] = await clanManagementContract.getClan(clanId.toNumber());
          return { clanId: clanId.toNumber(), clanName };
        })
      );
      setInvitations(invitationDetails);
      fetchCacheRef.current.invitations = invitationDetails; // Кэшируем результат
    } catch (error) {
      console.error('Failed to fetch invitations:', error);
      toast.error('Failed to fetch invitations.');
    }
  }, [clanManagementContract, account]);

  const fetchAllClans = useCallback(async () => {
    if (fetchCacheRef.current.allClans) {
      setAllClans(fetchCacheRef.current.allClans);
      return;
    }

    if (!clanManagementContract) {
      console.error("Clan management contract is not available.");
      return;
    }
    try {
      const clanCount = await clanManagementContract.nextClanId();
      const clans = [];

      for (let i = 1; i < clanCount.toNumber(); i++) {
        const [clanName, , , imageUrl] = await clanManagementContract.getClan(i);
        clans.push({ clanId: i, clanName, imageUrl });
      }

      setAllClans(clans);
      fetchCacheRef.current.allClans = clans; // Кэшируем результат
    } catch (error) {
      console.error('Failed to fetch clans:', error);
      toast.error('Failed to fetch clans.');
    }
  }, [clanManagementContract]);

  useEffect(() => {
    const fetchClanId = async () => {
      if (fetchCacheRef.current.clanId !== null) {
        setClanId(fetchCacheRef.current.clanId);
        return;
      }

      if (!clanManagementContract) {
        console.error("Clan management contract is not available.");
        return;
      }
      try {
        const id = await clanManagementContract.getPlayerClan(account);
        if (id && id.toNumber() !== 0) {
          setClanId(id.toNumber());
          fetchCacheRef.current.clanId = id.toNumber(); // Кэшируем результат
        } else {
          await fetchInvitations();
          await fetchAllClans();
        }
      } catch (error) {
        console.error('Failed to fetch clan ID:', error);
        toast.error('Failed to fetch clan ID.');
      }
    };

    fetchClanId();
  }, [clanManagementContract, account, fetchInvitations, fetchAllClans]);

  const handleCreateClan = async () => {
    if (formData.clanName && clanManagementContract) {
      try {
        setIsLoading(true);
        const tx = await clanManagementContract.createClan(formData.clanName, '', {
          value: ethers.utils.parseEther(clanCreationFee),
        });
        await tx.wait();

        const id = await clanManagementContract.getPlayerClan(account);
        setClanId(id.toNumber());
        fetchCacheRef.current.clanId = id.toNumber(); // Обновляем кэш

        setIsLoading(false);
        setFormData({ clanName: '' });
        toast.success('Clan created successfully!');
      } catch (error) {
        console.error('Failed to create clan:', error);
        setIsLoading(false);
        toast.error('Failed to create clan.');
      }
    } else {
      toast.warn('Please enter a clan name.');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAcceptInvitation = async (clanId: number) => {
    if (!clanManagementContract) return;
    try {
      const tx = await clanManagementContract.joinClan(clanId);
      await tx.wait();
      setClanId(clanId);
      fetchCacheRef.current.clanId = clanId; // Обновляем кэш
      toast.success('Joined the clan successfully!');
    } catch (error) {
      console.error('Failed to accept invitation:', error);
      toast.error('Failed to accept invitation.');
    }
  };

  const handleRejectInvitation = (clanId: number) => {
    setInvitations(invitations.filter((inv) => inv.clanId !== clanId));
    fetchCacheRef.current.invitations = invitations.filter((inv) => inv.clanId !== clanId); // Обновляем кэш
    toast.info('Rejected the invitation.');
  };

  const handleApplyToJoinClan = async (clanId: number) => {
    if (!clanManagementContract) return;
    try {
      const tx = await clanManagementContract.applyToJoinClan(clanId);
      await tx.wait();
      toast.success(`Applied to join clan ID: ${clanId}`);
    } catch (error) {
      console.error('Failed to apply to join clan:', error);
      toast.error('Failed to apply to join clan.');
    }
  };

  if (clanId && clanManagementContract && characterManagementContract && chatManagementContract && announcementBoardContract) {
    return (
      <ClanDashboard
        account={account}
        clanId={clanId}
        provider={provider}
        registeredName={registeredName}
        setRegisteredName={setRegisteredName}
      />
    );
  }

  return (
    <div className="clan-management">
      <h2>Clan Management</h2>
      <div>
        <label htmlFor="clanName">Clan Name:</label>
        <input
          type="text"
          id="clanName"
          name="clanName"
          value={formData.clanName}
          onChange={handleInputChange}
          placeholder="Enter clan name"
          pattern="^[a-zA-Z0-9\s]+$"
          required
        />
      </div>
      <button id="createClanButton" name="createClanButton" onClick={handleCreateClan} disabled={isLoading}>
        {isLoading ? 'Creating...' : 'Create Clan'}
      </button>

      {invitations.length > 0 && (
        <div className="invitations">
          <h3>Invitations</h3>
          <ul>
            {invitations.map((invitation, index) => (
              <li key={index}>
                {invitation.clanName} (ID: {invitation.clanId})
                <button id={`acceptInvitationButton-${index}`} name={`acceptInvitationButton-${index}`} onClick={() => handleAcceptInvitation(invitation.clanId)}>Accept</button>
                <button id={`rejectInvitationButton-${index}`} name={`rejectInvitationButton-${index}`} onClick={() => handleRejectInvitation(invitation.clanId)}>Reject</button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {allClans.length > 0 && (
        <div className="all-clans">
          <h3>All Clans</h3>
          <ul>
            {allClans.map((clan, index) => (
              <li key={index}>
                <img src={clan.imageUrl} alt={`${clan.clanName} Logo`} className="clan-avatar" />
                {clan.clanName} (ID: {clan.clanId})
                <button id={`applyJoinClanButton-${index}`} name={`applyJoinClanButton-${index}`} onClick={() => handleApplyToJoinClan(clan.clanId)}>Apply to Join</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ClanManagement;
