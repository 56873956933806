import React, { useEffect, useMemo } from 'react';
import CharacterSale from './CharacterSale';
import PublicSale from './PublicSale';
import SpaceShipManager from './SpaceShipManager';
import { ethers } from 'ethers';
import '../styles/MainPage.css';
import { Navigate } from 'react-router-dom';
import { getContracts } from '../contracts';

interface MainPageProps {
  account: string | null;
  registeredName: string | null;
  setRegisteredName: React.Dispatch<React.SetStateAction<string | null>>;
  provider: ethers.providers.Web3Provider | null;
}

const MainPage: React.FC<MainPageProps> = ({
  account,
  registeredName,
  setRegisteredName,
  provider,
}) => {
  const contracts = useMemo(() => {
    if (provider) {
      return getContracts(provider);
    }
    return {
      characterManagementContract: undefined,
      publicSaleContract: undefined,
      spaceShipManagerContract: undefined,
    };
  }, [provider]);

  useEffect(() => {
    if (provider && contracts) {
      // Выполняйте здесь любые начальные действия с контрактами
    }
  }, [contracts, provider]);

  if (!registeredName) {
    return <Navigate to="/login" />;
  }

  const {
    characterManagementContract,
    publicSaleContract,
    spaceShipManagerContract,
  } = contracts;

  return (
    <div className="main-page">
      <div className="login-section">
        <h1>{registeredName}</h1>
        <p>Your Account: {account || 'Not connected'}</p>
      </div>
      <div className="main-content">
        <div className="left-content public-sale">
          {publicSaleContract && provider ? (
            <PublicSale
              publicSaleContract={publicSaleContract}
              provider={provider}
            />
          ) : (
            <p>Loading Public Sale...</p>
          )}
        </div>
        <div className="center-content spaceship-manager">
          {spaceShipManagerContract && provider ? (
            <SpaceShipManager
              spaceShipManagerContract={spaceShipManagerContract}
              account={account || ''} 
              provider={provider}
            />
          ) : (
            <p>Loading SpaceShip Manager...</p>
          )}
        </div>
        <div className="right-content character-sale">
          {characterManagementContract ? (
            <CharacterSale
              characterManagementContract={characterManagementContract}
              account={account || ''} 
              provider={provider}           
            />
          ) : (
            <p>Loading Character Sale...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainPage;
