import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ethers, BigNumber } from 'ethers';
import CharacterManagementABI from '../abis/CharacterManagement.json';
import '../styles/LoginPage.css';

const CONTRACT_ADDRESS = '0x7f21801F46139b6c0aa6840036BA5Fb6e23f7E26';

interface LoginPageProps {
  currentAddress: string | null;
  provider: ethers.providers.Web3Provider | null;
}

const LoginPage: React.FC<LoginPageProps> = ({ currentAddress, provider }) => {
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [registeredName, setRegisteredName] = useState<string | null>(null);
  const [showInfoMessage, setShowInfoMessage] = useState<boolean>(false);
  const [showRegisterForm, setShowRegisterForm] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRegisteredName = async () => {
      if (currentAddress && provider) {
        try {
          const signer = provider.getSigner();
          const contract = new ethers.Contract(CONTRACT_ADDRESS, CharacterManagementABI, signer);
          const name = await contract.getRegisteredName(currentAddress);
          setRegisteredName(name);
        } catch (error) {
          console.error("Failed to fetch registered name:", error);
        }
      }
    };

    fetchRegisteredName();
  }, [currentAddress, provider]);

  useEffect(() => {
    setShowInfoMessage(true);
    setTimeout(() => {
      setShowRegisterForm(true);
    }, 2000); // Показать форму регистрации через 2 секунды после текста
  }, []);

  const handleRegister = async () => {
    try {
      setLoading(true);
      setErrorMessage(null);

      if (!currentAddress || !provider) {
        setErrorMessage("No wallet connected or authenticated.");
        return;
      }

      const signer = provider.getSigner();
      const contract = new ethers.Contract(CONTRACT_ADDRESS, CharacterManagementABI, signer);

      const registrationFee: BigNumber = ethers.utils.parseEther("0.00001");

      try {
        const gasLimit = await contract.estimateGas.registerName(name, {
          value: registrationFee,
        });

        console.log("Estimated Gas Limit:", gasLimit.toString());

        const tx = await contract.registerName(name, {
          value: registrationFee,
          gasLimit: gasLimit,
        });

        await tx.wait();
        alert("Name registered successfully!");
        navigate("/"); 
      } catch (gasError: unknown) {
        console.error("Gas estimation failed:", gasError);

        try {
          const tx = await contract.registerName(name, {
            value: registrationFee,
            gasLimit: ethers.BigNumber.from('100000'),
          });

          await tx.wait();
          alert("Name registered successfully with manual gas limit!");
          navigate("/");
        } catch (manualGasError: unknown) {
          console.error("Manual gas limit transaction failed:", manualGasError);
          setErrorMessage("Failed to estimate gas and manual gas limit transaction failed.");
        }
      }

    } catch (error: unknown) {
      console.error("Registration failed:", error);
      setErrorMessage((error as Error).message || "Failed to register name.");
    } finally {
      setLoading(false);
    }
  };

  if (!currentAddress || !provider) {
    return (
      <div className="login-container">
        <div className={`info-box ${showInfoMessage ? 'fade-in' : ''}`}>
          <h2>Welcome to Protonia</h2>
          <p className="info-message">
            To begin your journey in Protonia, connect an external wallet or create an internal wallet via email. Secure your unique name, participate in the public token sale, and acquire your first character. Dive into duels, join clans, and immerse yourself in the vibrant community. Explore, engage, and earn rewards in the ever-expanding universe of Protonia.
          </p>
          <p className="info-more">
            For more information, please check out our <button onClick={() => navigate('/whitepaper')} className="whitepaper-link">Whitepaper</button>.
          </p>
        </div>
      </div>
    );
  }

  if (registeredName) {
    return (
      <div className="login-container">
        <div className="login-box fade-in">
          <h2>Name Already Registered</h2>
          <p className="info-message">
            The name "{registeredName}" is already registered for this address. You can proceed with other activities like buying tokens, purchasing a character, joining duels, or exploring the galaxy.
          </p>
          <button onClick={() => navigate('/')} className="login-button">
            Go to Main Page
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="login-container">
      <div className={`info-box ${showInfoMessage ? 'fade-in' : ''}`}>
        <h2>Welcome to Protonia</h2>
        <p className="info-message">
          To begin your journey in Protonia, connect an external wallet or create an internal wallet via email. Secure your unique name, participate in the public token sale, and acquire your first character. Dive into duels, join clans, and immerse yourself in the vibrant community. Explore, engage, and earn rewards in the ever-expanding universe of Protonia.
        </p>
        <p className="info-more">
          For more information, please check out our <button onClick={() => navigate('/whitepaper')} className="whitepaper-link">Whitepaper</button>.
        </p>
      </div>
      {showRegisterForm && (
        <div className={`login-box ${showRegisterForm ? 'fade-in' : ''}`}>
          <h2>Register Your Name</h2>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            className="login-input"
          />
          <button onClick={handleRegister} disabled={loading} className="login-button">
            {loading ? "Registering..." : "Register Name"}
          </button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      )}
    </div>
  );
};

export default LoginPage;
