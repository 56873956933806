import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import '../styles/PublicSale.css';

interface PublicSaleProps {
  publicSaleContract: ethers.Contract;
  provider: ethers.providers.Web3Provider | null;
}

const PublicSale: React.FC<PublicSaleProps> = ({ publicSaleContract, provider }) => {
  const [tokenPrice, setTokenPrice] = useState<string | null>(null);
  const [purchaseAmount, setPurchaseAmount] = useState<string>('');
  const [ethAmount, setEthAmount] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [purchaseFee, setPurchaseFee] = useState<string>('0'); // Для хранения комиссии

  useEffect(() => {
    if (!provider || !publicSaleContract) {
      console.error("Provider or PublicSale contract is not defined.");
      return;
    }

    const getTokenPrice = async () => {
      try {
        const price = await publicSaleContract.tokenPrice();
        const fee = await publicSaleContract.purchaseFee(); // Получаем комиссию
        setTokenPrice(ethers.utils.formatEther(price));
        setPurchaseFee(ethers.utils.formatEther(fee)); // Устанавливаем комиссию
      } catch (error) {
        console.error('Failed to fetch token price or fee:', error);
        setStatus('Failed to fetch token price or fee.');
      }
    };

    getTokenPrice();
  }, [provider, publicSaleContract]);

  const handlePurchase = async () => {
    if (!provider || !publicSaleContract) {
      setStatus('Provider or PublicSale contract is not defined.');
      return;
    }

    const parsedAmount = parseFloat(purchaseAmount);
    if (isNaN(parsedAmount) || parsedAmount < 100) {
      setStatus('Minimum purchase is 100 tokens.');
      return;
    }

    try {
      const signer = provider.getSigner(); // Получаем подписанта
      const contractWithSigner = publicSaleContract.connect(signer); // Привязываем контракт к подписанту

      const ethAmountToSend = ethers.utils.parseEther(ethAmount);
      const tx = await contractWithSigner.buyTokens({ value: ethAmountToSend });
      await tx.wait();
      setStatus('Purchase successful!');
    } catch (err) {
      console.error('Purchase failed:', err);
      setStatus('Purchase failed. Please try again.');
    }
  };

  const handlePurchaseAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value;
    setPurchaseAmount(amount);

    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount) && tokenPrice !== null && !isNaN(parseFloat(tokenPrice))) {
      // Рассчитываем ETH для покупки с учетом комиссии
      const ethValue = parsedAmount * parseFloat(tokenPrice);
      const totalEth = ethValue + parseFloat(purchaseFee); // Добавляем комиссию
      setEthAmount(totalEth.toFixed(18)); // Точность до 18 знаков после запятой
    } else {
      setEthAmount(''); // Сброс ETH, если значение некорректное
    }
  };

  return (
    <div className="public-sale">
      <h2>Public Sale</h2>
      {tokenPrice !== null ? (
        <p>Token Price: {tokenPrice} ETH</p>
      ) : (
        <p>Loading token price...</p>
      )}
      <input
        type="text"
        id="purchaseAmount"
        name="purchaseAmount"
        value={purchaseAmount}
        onChange={handlePurchaseAmountChange}
        placeholder="Amount of tokens"
      />
      {ethAmount && <p>ETH Required: {ethAmount}</p>}
      <button 
        id="buyTokensButton" 
        name="buyTokensButton" 
        onClick={handlePurchase} 
        disabled={!ethAmount || !purchaseAmount || !provider || !publicSaleContract}
      >
        Buy Tokens
      </button>
      {status && <p>{status}</p>}
    </div>
  );
};

export default PublicSale;
