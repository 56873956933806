import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ethers } from 'ethers';
import '../styles/GalaxyPage.css';
import { getContracts } from '../contracts';

interface GalaxyPageProps {
  account: string;
  registeredName: string;
  provider: ethers.providers.Web3Provider;
}

interface PlanetInfo {
  id: number;
  name: string;
  rewardPercentage: string;
  rarity: string;
}

interface UserShip {
  id: number;
  name: string;
  rarity: string;
  bonus: number;
}

interface ActiveStake {
  planetId: number;
  amount: ethers.BigNumber;
  shipId: number;
  shipName?: string;
  planetName?: string;
}

const rarityColors: { [key: number]: string } = {
  0: 'white',
  1: 'green',
  2: 'blue',
  3: 'purple',
  4: 'orange',
};

const GalaxyPage: React.FC<GalaxyPageProps> = ({ account, registeredName, provider }) => {
  const [planetInfo, setPlanetInfo] = useState<PlanetInfo[]>([]);
  const [selectedPlanet, setSelectedPlanet] = useState<PlanetInfo | null>(null);
  const [userShips, setUserShips] = useState<UserShip[]>([]);
  const [selectedShip, setSelectedShip] = useState<number | null>(null);
  const [amount, setAmount] = useState<string>('');
  const [stakingFee] = useState<string>('0.00001');
  const [unstakingFee] = useState<string>('0.00001');
  const [activeStake, setActiveStake] = useState<ActiveStake | null>(null);
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [cooldownRemaining, setCooldownRemaining] = useState<number | null>(null);
  const [reward, setReward] = useState<string>('0');

  const contracts = useMemo(() => getContracts(provider), [provider]);

  useEffect(() => {
    const fetchPlanetInfo = async () => {
      try {
        const planets: PlanetInfo[] = [];
        for (let i = 0; i < 5; i++) {
          const planet = await contracts.stakingManagerContract.getPlanetInfo(i);
          planets.push({
            id: i,
            name: planet.name,
            rewardPercentage: planet.rewardPercentage.toString(),
            rarity: planet.rarity.toString(),
          });
        }
        setPlanetInfo(planets);
      } catch (error) {
        if (error instanceof Error) {
          console.error('Failed to fetch planet info:', error.message);
        } else {
          console.error('Failed to fetch planet info:', error);
        }
      }
    };

    fetchPlanetInfo();
  }, [contracts.stakingManagerContract]);

  useEffect(() => {
    const fetchUserShips = async () => {
      try {
        const ships: UserShip[] = [];
        const ownedShipIds = await contracts.spaceShipManagerContract.getOwnedShips(account);

        for (const shipId of ownedShipIds) {
          const name = await contracts.spaceShipManagerContract.getShipName(shipId);
          const rarity = await contracts.spaceShipManagerContract.getShipRarity(shipId);
          const bonus = await contracts.spaceShipManagerContract.getShipBonus(shipId);
          ships.push({
            id: shipId.toNumber(),
            name: name,
            rarity: rarity.toString(),
            bonus: parseFloat(bonus.toString()),
          });
        }
        setUserShips(ships);
      } catch (error) {
        if (error instanceof Error) {
          console.error('Failed to fetch user ships:', error.message);
        } else {
          console.error('Failed to fetch user ships:', error);
        }
      }
    };

    fetchUserShips();
  }, [contracts.spaceShipManagerContract, account]);

  const fetchActiveStake = useCallback(async () => {
    try {
      const stakeId = await contracts.stakingManagerContract.activeStake(account);
      if (stakeId.toNumber() !== 0) {
        const stake = await contracts.stakingManagerContract.getUserStake(account);
        setActiveStake(stake);

        const { stakeEndTime, cooldownEndTime } = await contracts.stakingManagerContract.getStakeAndCooldownTimes(account);
        const now = Math.floor(Date.now() / 1000);

        setTimeRemaining(stakeEndTime > now ? stakeEndTime - now : 0);
        setCooldownRemaining(cooldownEndTime > now ? cooldownEndTime - now : 0);

        const calculatedReward = await contracts.stakingManagerContract.calculateRewardPublic(stake.planetId, stake.amount, stake.shipId);
        setReward(ethers.utils.formatUnits(calculatedReward, 18));

        const shipName = await contracts.spaceShipManagerContract.getShipName(stake.shipId);
        const planetName = (await contracts.stakingManagerContract.getPlanetInfo(stake.planetId)).name;

        setActiveStake({
          ...stake,
          shipName: shipName,
          planetName: planetName,
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Failed to fetch active stake:', error.message);
      } else {
        console.error('Failed to fetch active stake:', error);
      }
    }
  }, [contracts.stakingManagerContract, contracts.spaceShipManagerContract, account]);

  useEffect(() => {
    fetchActiveStake();
  }, [fetchActiveStake]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (timeRemaining !== null && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => (prevTime !== null ? prevTime - 1 : null));
        if (activeStake) {
          const updateReward = async () => {
            const calculatedReward = await contracts.stakingManagerContract.calculateRewardPublic(activeStake.planetId, activeStake.amount, activeStake.shipId);
            setReward(ethers.utils.formatUnits(calculatedReward, 18));
          };
          updateReward();
        }
      }, 1000);
    }

    if (cooldownRemaining !== null && cooldownRemaining > 0) {
      timer = setInterval(() => {
        setCooldownRemaining((prevTime) => (prevTime !== null ? prevTime - 1 : null));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [timeRemaining, cooldownRemaining, activeStake, contracts.stakingManagerContract]);

  const handleSelectPlanet = (planet: PlanetInfo) => {
    const selectedShipRarity = userShips.find((ship) => ship.id === selectedShip)?.rarity;
    if (cooldownRemaining && cooldownRemaining > 0) {
      alert("You are in cooldown period and cannot stake right now.");
      return;
    }
    if (selectedShipRarity && Number(selectedShipRarity) >= Number(planet.rarity)) {
      setSelectedPlanet(planet);
    } else {
      alert("This ship's rarity is too low for this planet.");
    }
  };

  const handleSelectShip = (shipId: number) => {
    setSelectedShip(shipId);
  };

  const handleStake = async () => {
    if (selectedShip === null || selectedPlanet === null || amount === '') {
      alert("Please select a ship, a planet, and enter an amount to stake.");
      return;
    }

    try {
      const signer = provider.getSigner();
      const amountInTokens = ethers.utils.parseUnits(amount, 18);
      const stakeFeeInWei = ethers.utils.parseEther(stakingFee);

      const approveTx = await contracts.protoniaTokenContract.connect(signer).approve(contracts.stakingManagerContract.address, amountInTokens);
      await approveTx.wait();

      let gasLimit;
      try {
        gasLimit = await contracts.stakingManagerContract.connect(signer).estimateGas.stakeTokens(
          selectedShip,
          amountInTokens,
          selectedPlanet.id,
          { value: stakeFeeInWei }
        );
      } catch (gasError) {
        if (gasError instanceof Error) {
          console.error('Gas estimation failed:', gasError.message);
          alert(`Gas estimation failed: ${gasError.message}`);
        } else {
          console.error('Unknown error during gas estimation:', gasError);
          alert('Unknown error during gas estimation.');
        }
        return;
      }

      const tx = await contracts.stakingManagerContract.connect(signer).stakeTokens(
        selectedShip,
        amountInTokens,
        selectedPlanet.id,
        {
          value: stakeFeeInWei,
          gasLimit: gasLimit.add(ethers.BigNumber.from(100000)),
        }
      );
      await tx.wait();
      alert('Ship successfully staked!');

      fetchActiveStake();
    } catch (error) {
      if (error instanceof Error) {
        console.error('Failed to stake ship:', error.message);
      } else {
        console.error('Failed to stake ship:', error);
      }
    }
  };

  const handleUnstake = async () => {
    if (!activeStake) {
      alert('No active stake found.');
      return;
    }

    try {
      const signer = provider.getSigner();
      const unstakeFeeInWei = ethers.utils.parseEther(unstakingFee);

      let gasLimit;
      try {
        gasLimit = await contracts.stakingManagerContract.connect(signer).estimateGas.unstakeTokens(activeStake.shipId, {
          value: unstakeFeeInWei,
        });
      } catch (gasError) {
        if (gasError instanceof Error) {
          console.error('Gas estimation failed:', gasError.message);
          alert('Gas estimation failed. Please check your inputs.');
        } else {
          console.error('Unknown error during gas estimation:', gasError);
          alert('An unknown error occurred.');
        }
        return;
      }

      const tx = await contracts.stakingManagerContract.connect(signer).unstakeTokens(activeStake.shipId, {
        value: unstakeFeeInWei,
        gasLimit: gasLimit.add(ethers.BigNumber.from(100000)),
      });
      await tx.wait();
      alert('Ship successfully unstaked!');

      setActiveStake(null);
      setTimeRemaining(null);
      setCooldownRemaining(null);
      setReward('0');
    } catch (error) {
      if (error instanceof Error) {
        console.error('Failed to Unstake ship:', error.message);
        alert('Failed to Unstake ship.');
      } else {
        console.error('An unknown error occurred:', error);
        alert('An unknown error occurred.');
      }
    }
  };

  const handleClaim = async () => {
    if (!activeStake) {
      alert('No active stake found.');
      return;
    }

    try {
      const signer = provider.getSigner();
      const tx = await contracts.stakingManagerContract.connect(signer).claimReward();
      await tx.wait();
      alert('Reward successfully claimed!');

      fetchActiveStake();
    } catch (error) {
      if (error instanceof Error) {
        console.error('Failed to claim reward:', error.message);
        alert('Failed to claim reward.');
      } else {
        console.error('An unknown error occurred:', error);
        alert('An unknown error occurred.');
      }
    }
  };

  return (
    <div className="staking-galaxy-page-container">
      <div className="staking-garage-container">
        <h1>Galaxy Overview</h1>
        <h2>Welcome {registeredName}</h2>
        <div>
          <h3>Your Ships</h3>
          {userShips.length > 0 ? (
            <ul>
              {userShips.map((ship) => (
                <li
                  key={ship.id}
                  onClick={() => handleSelectShip(ship.id)}
                  className={`staking-ship-container ${selectedShip === ship.id ? 'selected-ship' : ''}`}
                  style={{ color: rarityColors[Number(ship.rarity)] }}
                >
                  <img
                    src={`/ships/ship${ship.rarity}.png`}
                    alt={ship.name}
                    className="staking-ship-image"
                  />
                  {ship.name} (Rarity: {ship.rarity}, Bonus: {ship.bonus}%)
                </li>
              ))}
            </ul>
          ) : (
            <p>No ships available.</p>
          )}
        </div>
        <div>
          <h3>Select a Planet to Stake On</h3>
          {planetInfo.length > 0 ? (
            <ul>
              {planetInfo.map((planet) => (
                <li
                  key={planet.id}
                  onClick={() => handleSelectPlanet(planet)}
                  className={`staking-planet-container ${selectedPlanet?.id === planet.id ? 'selected-planet' : ''}`}
                  style={{
                    color: selectedPlanet?.id === planet.id ? 'blue' : rarityColors[Number(planet.rarity)],
                    textDecoration: selectedPlanet?.id === planet.id ? 'none' : 'line-through',
                    cursor: selectedPlanet?.id === planet.id ? 'pointer' : 'not-allowed',
                  }}
                >
                  <img
                    src={`/planets/planet${planet.rarity}.png`}
                    alt={planet.name}
                    className="staking-planet-image"
                  />
                  {planet.name} (Reward: {planet.rewardPercentage}%, Rarity: {planet.rarity})
                </li>
              ))}
            </ul>
          ) : (
            <p>No planets available.</p>
          )}
        </div>
        {selectedPlanet && (
          <div>
            <h3>Selected Planet: {selectedPlanet.name}</h3>
          </div>
        )}
        <div>
          <h3>Enter amount to stake</h3>
          <input
            type="text"
            id="staking-amountToStake"
            name="staking-amountToStake"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount in tokens"
            className="staking-input"
          />
        </div>
        <button
          id="staking-stakeButton"
          name="staking-stakeButton"
          onClick={handleStake}
          disabled={selectedShip === null || selectedPlanet === null || amount === ''}
          className="staking-button"
        >
          Stake Ship (Fee: {stakingFee} ETH)
        </button>
      </div>
      {activeStake && (
        <div className="staking-stake-info">
          <h3>Active Stake:</h3>
          <p style={{ color: rarityColors[Number(userShips.find((ship) => ship.id === activeStake.shipId)?.rarity)] }}>
            Ship Name: {activeStake.shipName || 'Unknown'}
          </p>
          <p style={{ color: rarityColors[Number(planetInfo.find((planet) => planet.id === activeStake.planetId)?.rarity)] }}>
            Planet Name: {activeStake.planetName || 'Unknown'}
          </p>
          <p>Amount Staked: {ethers.utils.formatUnits(activeStake.amount, 18)} tokens</p>
          <p>Current Reward: {reward} tokens</p>
          <p>
            Time Remaining: {timeRemaining !== null
              ? `${Math.floor(timeRemaining / 3600)}h ${Math.floor((timeRemaining % 3600) / 60)}m ${timeRemaining % 60}s`
              : 'Loading...'}
          </p>
          {cooldownRemaining !== null && cooldownRemaining > 0 && (
            <p>
              Cooldown Remaining: {`${Math.floor(cooldownRemaining / 3600)}h ${Math.floor((cooldownRemaining % 3600) / 60)}m ${cooldownRemaining % 60}s`}
            </p>
          )}
          <button
            id="staking-claimButton"
            name="staking-claimButton"
            onClick={handleClaim}
            className="staking-button"
          >
            Claim Reward
          </button>
          <button
            id="staking-unstakeButton"
            name="staking-unstakeButton"
            onClick={handleUnstake}
            disabled={timeRemaining !== null && timeRemaining > 0}
            className="staking-button"
          >
            Unstake Ship (Fee: {unstakingFee} ETH)
          </button>
        </div>
      )}
    </div>
  );
};

export default GalaxyPage;
