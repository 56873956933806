import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import ProtoniaTokenABI from '../abis/ProtoniaToken.json';
import { getContracts } from '../contracts';
import './DuelLobby.css';

interface DuelLobbyProps {
  provider: ethers.providers.Web3Provider | null;
  internalProvider: ethers.providers.Web3Provider | null;
  account: string;
  duelContractAddress: string;
  characterManagementAddress: string;
  tokenAddress: string;
}

interface DuelRequest {
  player: string;
  characterId: ethers.BigNumber;
  wager: ethers.BigNumber;
  active: boolean;
}

interface Contracts {
  duelContract: ethers.Contract;
  characterManagementContract: ethers.Contract;
  tokenContract: ethers.Contract;
}

const DuelLobby: React.FC<DuelLobbyProps> = ({
  provider,
  internalProvider,
  account,
  duelContractAddress,
  characterManagementAddress,
  tokenAddress,
}) => {
  const [wager, setWager] = useState<string>('');
  const [duelRequests, setDuelRequests] = useState<DuelRequest[]>([]);
  const [activeCharacterId, setActiveCharacterId] = useState<number | null>(null);
  const [logs, setLogs] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [minWager, setMinWager] = useState<string>('1');
  const [maxWager, setMaxWager] = useState<string>('1000000');
  const [signer, setSigner] = useState<ethers.Signer | null>(null);
  const [contracts, setContracts] = useState<Contracts | null>(null);
  const [isCreatingDuel, setIsCreatingDuel] = useState<boolean>(false);
  const [isAcceptingDuel, setIsAcceptingDuel] = useState<boolean>(false);
  const navigate = useNavigate();

  // Инициализация подписанта
  useEffect(() => {
    if (provider) {
      setSigner(provider.getSigner());
    } else if (internalProvider) {
      setSigner(internalProvider.getSigner());
    } else {
      setLogs((prevLogs) => [...prevLogs, 'No provider available']);
    }
  }, [provider, internalProvider]);

  // Инициализация контрактов
  useEffect(() => {
    if (signer) {
      const contractsInstance = getContracts(signer);
      setContracts({
        ...contractsInstance,
        tokenContract: new ethers.Contract(tokenAddress, ProtoniaTokenABI, signer),
      });

      // Получение minWager и maxWager из контракта
      const fetchWagerLimits = async () => {
        try {
          const minWagerFromContract = await contractsInstance.duelContract.minWager();
          const maxWagerFromContract = await contractsInstance.duelContract.maxWager();
          setMinWager(ethers.utils.formatUnits(minWagerFromContract, 18)); // Убедитесь, что используете правильные единицы
          setMaxWager(ethers.utils.formatUnits(maxWagerFromContract, 18));
        } catch (error) {
          setLogs((prevLogs) => [...prevLogs, 'Failed to fetch wager limits']);
        }
      };

      fetchWagerLimits();

      // Прослушивание события завершения дуэли
      contractsInstance.duelContract.on('DuelEnded', (duelId, winner) => {
        setLogs((prevLogs) => [...prevLogs, `Duel ${duelId} completed. Winner: ${winner}`]);
        navigate(`/duel-completed/${duelId}`);
      });
    }
  }, [signer, tokenAddress, navigate]);

  // Получение активного персонажа через контракт CharacterManagement
  useEffect(() => {
    if (contracts) {
      const fetchActiveCharacter = async () => {
        try {
          const characterId = await contracts.characterManagementContract.ownerToCharacter(account);
          if (characterId && characterId.toNumber() > 0) {
            setActiveCharacterId(characterId.toNumber());
          } else {
            setLogs((prevLogs) => [
              ...prevLogs,
              'No active character for this account. Please create one to start a duel.',
            ]);
          }
        } catch (err) {
          setLogs((prevLogs) => [...prevLogs, 'Failed to load active character']);
        }
      };
      fetchActiveCharacter();
    }
  }, [contracts, account]);

  // Загрузка активных дуэлей через getDuelRequests
  const fetchDuelRequests = useCallback(async () => {
    if (!contracts) return;
    try {
      setLoading(true);
      const requests = await contracts.duelContract.getDuelRequests();
      setDuelRequests(requests);
    } catch (err) {
      setLogs((prevLogs) => [...prevLogs, 'Failed to load duel requests']);
    } finally {
      setLoading(false);
    }
  }, [contracts]);

  useEffect(() => {
    if (contracts) {
      fetchDuelRequests();
    }
  }, [fetchDuelRequests, contracts]);

  // Создание дуэльного запроса
  const createDuelRequest = async () => {
    if (!contracts || !activeCharacterId || !wager || parseFloat(wager) <= 0) {
      setLogs((prevLogs) => [...prevLogs, 'Invalid wager or no active character']);
      return;
    }

    if (parseFloat(wager) < parseFloat(minWager) || parseFloat(wager) > parseFloat(maxWager)) {
      setLogs((prevLogs) => [...prevLogs, `Wager must be between ${minWager} and ${maxWager} PRTN`]);
      return;
    }

    setIsCreatingDuel(true);
    try {
      const parsedWager = ethers.utils.parseUnits(wager, 18); // Убедитесь, что используете parseUnits для корректного преобразования

      // Проверяем текущую квоту токенов для контракта дуэлей
      const allowance = await contracts.tokenContract.allowance(account, contracts.duelContract.address);
      if (allowance.lt(parsedWager)) {
        const txApprove = await contracts.tokenContract.approve(contracts.duelContract.address, parsedWager);
        await txApprove.wait();
        setLogs((prevLogs) => [...prevLogs, `Approved ${wager} PRTN for duel contract.`]);
      }

      // Создание дуэльного запроса
      const tx = await contracts.duelContract.createDuelRequest(activeCharacterId, parsedWager);
      await tx.wait();

      setLogs((prevLogs) => [...prevLogs, `Duel request created with a stake of ${wager} PRTN.`]);

      // Повторный вызов функции для получения обновленных дуэлей
      await fetchDuelRequests();
    } catch (err) {
      const errorMessage = (err as Error).message || 'Failed to create duel request';
      setLogs((prevLogs) => [...prevLogs, errorMessage]);
    } finally {
      setIsCreatingDuel(false);
    }
  };

  // Принятие дуэльного запроса
  const acceptDuelRequest = async (duelId: number) => {
    if (!contracts || !activeCharacterId) {
      setLogs((prevLogs) => [...prevLogs, 'No active character']);
      return;
    }

    setIsAcceptingDuel(true);
    try {
      const duel = duelRequests[duelId];
      if (!duel.active) {
        throw new Error(`Duel with ID: ${duelId} is not active.`);
      }

      const stakeAmount = duel.wager;

      // Проверка квоты токенов
      const allowance = await contracts.tokenContract.allowance(account, contracts.duelContract.address);
      if (allowance.lt(stakeAmount)) {
        const txApprove = await contracts.tokenContract.approve(contracts.duelContract.address, stakeAmount);
        await txApprove.wait();
        setLogs((prevLogs) => [...prevLogs, `Approved ${ethers.utils.formatEther(stakeAmount.toString())} PRTN for duel contract.`]);
      }

      // Принятие дуэли
      const txAccept = await contracts.duelContract.acceptDuelRequest(duelId, activeCharacterId);
      await txAccept.wait();
      setLogs((prevLogs) => [...prevLogs, `Accepted duel request with ID: ${duelId}`]);

      // Перенаправление на страницу дуэли
      navigate(`/duel/${duelId}`);
    } catch (err) {
      const errorMessage = (err as Error).message || 'Failed to accept duel';
      setLogs((prevLogs) => [...prevLogs, errorMessage]);
    } finally {
      setIsAcceptingDuel(false);
    }
  };
  useEffect(() => {
    if (contracts) {
      const handleDuelStarted = (duelId: number, player1: string, player2: string) => {
        if (account === player1 || account === player2) {
          setLogs((prevLogs) => [...prevLogs, `Duel started. Duel ID: ${duelId}`]);
          navigate(`/duel/${duelId}`); // Перенаправляем игрока на страницу дуэли
        }
      };

      contracts.duelContract.on('DuelStarted', handleDuelStarted);

      return () => {
        contracts.duelContract.off('DuelStarted', handleDuelStarted);
      };
    }
  }, [contracts, account, navigate]);
  return (
    <div className="duel-lobby">
      <h1>Duel Lobby</h1>

      <div className="duel-form">
        <label htmlFor="wager">Wager (in PRTN):</label>
        <input
          type="text"
          id="wager"
          value={wager}
          onChange={(e) => setWager(e.target.value)}
        />
        <p>Wager must be between {minWager} and {maxWager} PRTN</p>

        <button onClick={createDuelRequest} disabled={!signer || !activeCharacterId || isCreatingDuel}>
          {isCreatingDuel ? 'Creating Duel...' : 'Create Duel Request'}
        </button>
      </div>

      <div className="duel-requests">
        <h2>Available Duel Requests</h2>
        {loading ? (
          <p>Loading duel requests...</p>
        ) : duelRequests.length === 0 ? (
          <p>No active duel requests available</p>
        ) : (
          duelRequests.map((request, index) => (
            <div key={index} className="duel-request">
              <p>Challenger: {request.player}</p>
              <p>Character ID: {request.characterId.toString()}</p>
              <p>Wager: {ethers.utils.formatEther(request.wager.toString())} PRTN</p>
              <button onClick={() => acceptDuelRequest(index)} disabled={isAcceptingDuel}>
                {isAcceptingDuel ? 'Accepting...' : 'Accept'}
              </button>
            </div>
          ))
        )}
      </div>

      <div className="logs">
        <h3>Logs</h3>
        {logs.map((log, index) => (
          <p key={index}>{log}</p>
        ))}
      </div>
    </div>
  );
};

export default DuelLobby;
