import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ethers } from 'ethers';
import CharacterManagementABI from '../abis/CharacterManagement.json';
import '../styles/ProfilePage.css';
import characterImage from '../assets/character.png';

interface ProfilePageProps {
  account: string;
  provider: ethers.providers.Web3Provider | null;
  characterManagementAddress: string;
}

interface Character {
  id: number;
  strength: number;
  health: number;
  maxHealth: number;
  victories: number;
  defeats: number;
  experience: number;
  level: number;
  points: number;
  rarity: number;
  name: string;
  race: string;
  inventory: number[];
}

const rarityColors: { [key: number]: string } = {
  0: '#cccccc', // Common
  1: '#00ff00', // Uncommon
  2: '#0000ff', // Rare
  3: '#800080', // Epic
  4: '#ff0000'  // Legendary
};

const ProfilePage: React.FC<ProfilePageProps> = ({
  account,
  provider,
  characterManagementAddress,
}) => {
  const [character, setCharacter] = useState<Character | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [strengthPoints, setStrengthPoints] = useState<number>(0);
  const [healthPoints, setHealthPoints] = useState<number>(0);

  const characterManagementContract = useMemo(() => {
    if (!provider) return null;
    return new ethers.Contract(characterManagementAddress, CharacterManagementABI, provider.getSigner());
  }, [characterManagementAddress, provider]);

  const fetchCharacter = useCallback(async () => {
    if (!characterManagementContract) return;
    setLoading(true);
    try {
      const characterId = await characterManagementContract.ownerToCharacter(account);
      if (characterId.eq(0)) {
        setCharacter(null);
        setError('No character found for this account.');
      } else {
        const characterData = await characterManagementContract.getCharacter(characterId);
        const formattedCharacter: Character = {
          id: characterId.toNumber(),
          strength: characterData.strength.toNumber(),
          health: characterData.health.toNumber(),
          maxHealth: characterData.maxHealth.toNumber(),
          victories: characterData.victories.toNumber(),
          defeats: characterData.defeats.toNumber(),
          experience: characterData.experience.toNumber(),
          level: characterData.level.toNumber(),
          points: characterData.points.toNumber(),
          rarity: characterData.rarity.toNumber(),
          name: characterData.name,
          race: characterData.race,
          inventory: characterData.inventory.map((item: ethers.BigNumber) => item.toNumber()),
        };
        setCharacter(formattedCharacter);
      }
    } catch (error) {
      console.error('Failed to fetch character data:', error);
      setError('Failed to fetch character data.');
    } finally {
      setLoading(false);
    }
  }, [account, characterManagementContract]);

  useEffect(() => {
    if (account && characterManagementContract) {
      fetchCharacter();
    }
  }, [account, characterManagementContract, fetchCharacter]);

  const calculateHealthPercentage = (health: number, maxHealth: number) => {
    if (maxHealth === 0) return 0;
    return (health / maxHealth) * 100;
  };

  const calculateExperiencePercentage = (experience: number) => {
    const maxExperience = 100; // Настройте это значение в соответствии с системой опыта вашей игры
    return (experience / maxExperience) * 100;
  };

  const handleAllocatePoints = async () => {
    if (!character || !characterManagementContract) return;
    try {
      const tx = await characterManagementContract.allocatePoints(character.id, strengthPoints, healthPoints, {
        gasLimit: ethers.utils.hexlify(500000)  // Примерная оценка, может потребоваться увеличение
      });
      await tx.wait();
      fetchCharacter(); // Обновляем данные персонажа после распределения очков
      setStrengthPoints(0); // Сбрасываем значения после распределения
      setHealthPoints(0);
    } catch (error) {
      console.error('Failed to allocate points:', error);
      setError('Failed to allocate points.');
    }
  };

  const incrementStrength = () => {
    if (character && strengthPoints + healthPoints < character.points) {
      setStrengthPoints(strengthPoints + 1);
    }
  };

  const decrementStrength = () => {
    if (strengthPoints > 0) {
      setStrengthPoints(strengthPoints - 1);
    }
  };

  const incrementHealth = () => {
    if (character && strengthPoints + healthPoints < character.points) {
      setHealthPoints(healthPoints + 1);
    }
  };

  const decrementHealth = () => {
    if (healthPoints > 0) {
      setHealthPoints(healthPoints - 1);
    }
  };

  return (
    <div className="profile-page">
      <h1>NFT PROFILE</h1>
      <div className="profile-container">
        {loading && <p>Loading...</p>}
        {error && <p className="error-message">{error}</p>}
        {character ? (
          <>
            <div className="character-container">
              <div className="bars-container">
                <div className="health-bar">
                  <span>{`Health: ${character.health} / ${character.maxHealth}`}</span>
                  <div style={{ width: `${calculateHealthPercentage(character.health, character.maxHealth)}%`, height: '100%', background: 'linear-gradient(135deg, #ff6a00, #ff0000)' }}></div>
                </div>
                <div className="experience-bar">
                  <span>{`Experience: ${character.experience}`}</span>
                  <div style={{ width: `${calculateExperiencePercentage(character.experience)}%`, height: '100%', background: 'linear-gradient(135deg, #ccc, #888)' }}></div>
                </div>
              </div>
              <img src={characterImage} alt="Character" className="character-image" />
            </div>
            <div className="character-info">
              <p>{character.name} {character.level !== undefined ? `(Level: ${character.level})` : ''}</p>
              <p>Race: {character.race}</p>
              <p style={{ color: rarityColors[character.rarity] }}>Rarity: {['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'][character.rarity]}</p>
              <p>Strength: {character.strength}</p>
              <p>Victories: {character.victories}</p>
              <p>Defeats: {character.defeats}</p>
              <p>Available Points: {character.points - strengthPoints - healthPoints}</p>
              <div className="points-allocation">
                <h2>Allocate Points</h2>
                <div className="points-controls">
                  <div className="points-group">
                    <label>Strength Points:</label>
                    <button className="points-button" onClick={decrementStrength}>-</button>
                    <span>{strengthPoints}</span>
                    <button className="points-button" onClick={incrementStrength}>+</button>
                  </div>
                  <div className="points-group">
                    <label>Health Points:</label>
                    <button className="points-button" onClick={decrementHealth}>-</button>
                    <span>{healthPoints}</span>
                    <button className="points-button" onClick={incrementHealth}>+</button>
                  </div>
                </div>
                <button className="allocate-button" onClick={handleAllocatePoints}>Allocate Points</button>
              </div>
            </div>
            <div className="inventory">
              <h2>Inventory</h2>
              <div className="inventory-grid">
                {character.inventory.map((itemId: number, index: number) => (
                  <div key={index} className="inventory-item">
                    {itemId > 0 ? `Item ${itemId}` : 'Empty'}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <p>No character found.</p>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
