import React, { useEffect, useState } from 'react';
import '../styles/Planets.css';

const getRandomPosition = () => ({
  top: `${Math.floor(Math.random() * 100)}%`,
  left: `${Math.floor(Math.random() * 100)}%`,
});

const getRandomDelay = () => ({
  animationDelay: `${Math.random() * 10}s`,
  animationDuration: `${Math.random() * 5 + 5}s`,
});

const createStars = (numStars: number) => {
  const stars = [];
  for (let i = 0; i < numStars; i++) {
    stars.push(
      <div key={i} className="star" style={{ ...getRandomPosition(), ...getRandomDelay() }}></div>
    );
  }
  return stars;
};

const Planets: React.FC = () => {
  const [stars, setStars] = useState<JSX.Element[]>([]);

  useEffect(() => {
    setStars(createStars(500));
    const ship = document.querySelector('.ship');
    ship?.classList.add('animate-ship');
  }, []);

  return (
    <div className="planet-container">
      {stars}
      <div className="sun-container">
        <div className="planet sun"></div>
      </div>
      <div className="planet mercury"></div>
      <div className="planet venus"></div>
      <div className="planet earth">
        <div className="moon"></div>
      </div>
      <div className="planet mars"></div>
      <div className="planet jupiter"></div>
      <div className="planet saturn"></div>
      <div className="planet uranus"></div>
      <div className="planet neptune"></div>
      <div className="ship"></div>
    </div>
  );
};

export default Planets;
