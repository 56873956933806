import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import './DuelFightPage.css';
import { getContracts } from '../contracts';

interface DuelFightPageProps {
  provider: ethers.providers.Web3Provider | null;
  internalProvider: ethers.providers.Web3Provider | null;
  account: string;
}

interface Character {
  name: string;
  health: ethers.BigNumber;
  maxHealth: ethers.BigNumber;
  strength: ethers.BigNumber;
}

interface Duel {
  character1Id: ethers.BigNumber;
  character2Id: ethers.BigNumber;
  player1: string;
  player2: string;
  isActive: boolean;
  isCompleted: boolean;
  player1ActionSubmitted: boolean;
  player2ActionSubmitted: boolean;
}

const DuelFightPage: React.FC<DuelFightPageProps> = ({
  provider,
  internalProvider,
  account,
}) => {
  const { duelId } = useParams<{ duelId: string }>();
  const [duel, setDuel] = useState<Duel | null>(null);
  const [character, setCharacter] = useState<Character | null>(null);
  const [opponentCharacter, setOpponentCharacter] = useState<Character | null>(null);
  const [attack, setAttack] = useState<number>(0);
  const [defense, setDefense] = useState<number>(0);
  const [logs, setLogs] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [duelStarted, setDuelStarted] = useState<boolean>(false);
  const [duelEnded, setDuelEnded] = useState<boolean>(false);

  // Получение подписанта для взаимодействия с контрактом
  const getSigner = useMemo(() => {
    if (internalProvider) {
      return internalProvider.getSigner();
    }
    if (provider) {
      return provider.getSigner();
    }
    throw new Error('No provider available');
  }, [provider, internalProvider]);

  // Инициализация контрактов
  const contracts = useMemo(() => getContracts(getSigner), [getSigner]);

  // Загрузка данных дуэли и персонажей
  useEffect(() => {
    const fetchDuel = async () => {
      try {
        if (!duelId || !contracts) return;
  
        const duelData = await contracts.duelContract.duels(duelId);
        setDuel(duelData);
  
        console.log("Duel Data:", duelData);  // Логирование данных дуэли
  
        const [character1Data, character2Data] = await Promise.all([
          contracts.characterManagementContract.getCharacter(duelData.character1Id),
          contracts.characterManagementContract.getCharacter(duelData.character2Id),
        ]);
  
        // Определяем, кто текущий игрок и кто соперник
        if (duelData.player1.toLowerCase() === account.toLowerCase()) {
          setCharacter(character1Data);
          setOpponentCharacter(character2Data);
          console.log("You are Player 1");
        } else if (duelData.player2.toLowerCase() === account.toLowerCase()) {
          setCharacter(character2Data);
          setOpponentCharacter(character1Data);
          console.log("You are Player 2");
        } else {
          setLogs((prevLogs) => [...prevLogs, 'You are not a participant in this duel']);
          console.error('Not a participant');
          return;
        }
  
        if (duelData.isActive) {
          setLogs((prevLogs) => [...prevLogs, 'Duel has already started']);
          setDuelStarted(true);
        }
  
        setLoading(false);
      } catch (err) {
        setLogs((prevLogs) => [...prevLogs, 'Error fetching duel data.']);
      }
    };
  
    fetchDuel();
  }, [duelId, contracts, account]);
  
  // Начало дуэли через кнопку
  const startDuel = async () => {
    if (!duelId || !contracts || duelStarted) return;
    try {
      const gasEstimate = await contracts.duelContract.estimateGas.startDuel(duelId);
      const txStart = await contracts.duelContract.startDuel(duelId, { gasLimit: gasEstimate });
      await txStart.wait();
      setLogs((prevLogs) => [...prevLogs, `Duel started with ID: ${duelId}`]);
      setDuelStarted(true);
    } catch (err) {
      setLogs((prevLogs) => [...prevLogs, 'Error starting duel']);
    }
  };

  // Обработка действия игрока и отправка на контракт
  const submitAction = async () => {
    if (!duel || duelEnded) {
      setLogs((prevLogs) => [...prevLogs, 'Duel has ended or is invalid']);
      return;
    }
  
    // Проверяем, что текущий аккаунт — участник дуэли
    if (duel.player1.toLowerCase() === account.toLowerCase() && duel.player1ActionSubmitted) {
      setLogs((prevLogs) => [...prevLogs, 'Player 1 has already submitted action']);
      return;
    }
  
    if (duel.player2.toLowerCase() === account.toLowerCase() && duel.player2ActionSubmitted) {
      setLogs((prevLogs) => [...prevLogs, 'Player 2 has already submitted action']);
      return;
    }
  
    try {
      // Проверяем, что текущий аккаунт — участник дуэли
      if (duel.player1.toLowerCase() !== account.toLowerCase() && duel.player2.toLowerCase() !== account.toLowerCase()) {
        setLogs((prevLogs) => [...prevLogs, 'You are not a participant']);
        return;
      }
  
      // Оценка газа для действия
      const gasEstimate = await contracts.duelContract.estimateGas.submitDuelAction(duelId, attack, defense);
      const tx = await contracts.duelContract.submitDuelAction(duelId, attack, defense, {
        gasLimit: gasEstimate,
      });
  
      await tx.wait();
  
      setLogs((prevLogs) => [
        ...prevLogs,
        `You attacked ${opponentCharacter?.name} on ${getBodyPart(attack)} and defended ${getBodyPart(defense)}`
      ]);
  
      const updatedCharacter1 = await contracts.characterManagementContract.getCharacter(duel.character1Id);
      const updatedCharacter2 = await contracts.characterManagementContract.getCharacter(duel.character2Id);
  
      if (updatedCharacter1.health.eq(0) || updatedCharacter2.health.eq(0)) {
        setDuelEnded(true);
        setLogs((prevLogs) => [...prevLogs, 'The duel has ended.']);
      }
  
      setDuel({
        ...duel,
        player1ActionSubmitted: account === duel.player1 ? true : duel.player1ActionSubmitted,
        player2ActionSubmitted: account === duel.player2 ? true : duel.player2ActionSubmitted,
      });
    } catch (err) {
      const errorMessage = (err as Error).message || 'Unknown error';
      setLogs((prevLogs) => [...prevLogs, `Error submitting action: ${errorMessage}`]);
    }
  };
  

  // Проверка обоих действий, обновление раунда и состояния персонажей
  const checkRoundUpdate = useCallback(async () => {
    if (!duel || !contracts) return;

    if (duel.player1ActionSubmitted && duel.player2ActionSubmitted) {
      try {
        const updatedDuel = await contracts.duelContract.duels(duelId);
        setDuel(updatedDuel);

        const [updatedCharacter1, updatedCharacter2] = await Promise.all([
          contracts.characterManagementContract.getCharacter(duel.character1Id),
          contracts.characterManagementContract.getCharacter(duel.character2Id),
        ]);

        setCharacter(account === duel.player1 ? updatedCharacter1 : updatedCharacter2);
        setOpponentCharacter(account === duel.player1 ? updatedCharacter2 : updatedCharacter1);

        if (updatedCharacter1.health.eq(0) || updatedCharacter2.health.eq(0)) {
          setDuelEnded(true);
          setLogs((prevLogs) => [...prevLogs, 'The duel has ended.']);
        }
      } catch (err) {
        setLogs((prevLogs) => [...prevLogs, 'Error updating round data.']);
      }
    }
  }, [duel, contracts, duelId, account]);

  useEffect(() => {
    checkRoundUpdate();
  }, [duel, checkRoundUpdate]);

  // Утилита для отображения частей тела в UI
  const getBodyPart = (index: number) => {
    const parts = ['Head', 'Torso', 'Stomach', 'Legs'];
    return parts[index];
  };

  // Подсчёт процента здоровья
  const calculateHealthPercentage = (health: ethers.BigNumber, maxHealth: ethers.BigNumber) => {
    return (health.toNumber() / maxHealth.toNumber()) * 100;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="duel-fight-page">
      <h1>Duel Fight</h1>
      <div className="duel-container">
        <div className="character">
          <h2>Your Character</h2>
          <p>Name: {character?.name}</p>
          <div className="health-bar">
            <span style={{ width: `${calculateHealthPercentage(character?.health ?? ethers.BigNumber.from(0), character?.maxHealth ?? ethers.BigNumber.from(1))}%` }}></span>
          </div>
          <p>Health: {character?.health?.toString()}</p>
          <p>Strength: {character?.strength?.toString()}</p>
        </div>

        <div className="actions">
          {!duelStarted ? (
            <div>
              <button onClick={startDuel}>Start Duel</button>
            </div>
          ) : duelEnded ? (
            <div>
              <p>The duel has ended.</p>
            </div>
          ) : (
            <div>
              <label>Attack:</label>
              <select value={attack} onChange={(e) => setAttack(Number(e.target.value))}>
                <option value={0}>Head</option>
                <option value={1}>Torso</option>
                <option value={2}>Stomach</option>
                <option value={3}>Legs</option>
              </select>
              <label>Defense:</label>
              <select value={defense} onChange={(e) => setDefense(Number(e.target.value))}>
                <option value={0}>Head</option>
                <option value={1}>Torso</option>
                <option value={2}>Stomach</option>
                <option value={3}>Legs</option>
              </select>
              <button onClick={submitAction}>Submit Action</button>
            </div>
          )}
        </div>

        <div className="opponent-character">
          <h2>Opponent Character</h2>
          <p>Name: {opponentCharacter?.name}</p>
          <div className="health-bar">
            <span style={{ width: `${calculateHealthPercentage(opponentCharacter?.health ?? ethers.BigNumber.from(0), opponentCharacter?.maxHealth ?? ethers.BigNumber.from(1))}%` }}></span>
          </div>
          <p>Health: {opponentCharacter?.health?.toString()}</p>
          <p>Strength: {opponentCharacter?.strength?.toString()}</p>
        </div>
      </div>

      <div className="logs">
        <h3>Logs</h3>
        {logs.map((log, index) => (
          <p key={index}>{log}</p>
        ))}
      </div>
    </div>
  );
};

export default DuelFightPage;
