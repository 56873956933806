import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import '../styles/ClanDashboard.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ethers, BigNumber } from 'ethers';
import { getContracts } from '../contracts';

interface ClanInfo {
  name: string;
  image: string;
  leader: string;
  experience: BigNumber;
  level: BigNumber;
}

interface Announcement {
  sender: string;
  content: string;
  timestamp: number;
  pinned?: boolean;
}

interface ClanDashboardProps {
  account: string;
  clanId: number;
  provider: ethers.providers.Web3Provider;
  registeredName: string | null;
  setRegisteredName: React.Dispatch<React.SetStateAction<string | null>>;
}

const ClanDashboard: React.FC<ClanDashboardProps> = ({
  account,
  clanId,
  provider,
  registeredName,
  setRegisteredName,
}) => {
  const signer = useMemo(() => provider.getSigner(), [provider]);

  const { 
    clanManagementContract: clanManagementReadContract, 
    characterManagementContract, 
    chatManagementContract, 
    announcementBoardContract 
  } = useMemo(() => getContracts(provider), [provider]); // мемоизация получения контрактов

  const clanManagementContract = useMemo(() => clanManagementReadContract.connect(signer), [clanManagementReadContract, signer]);
  const chatManagementContractWithSigner = useMemo(() => chatManagementContract.connect(signer), [chatManagementContract, signer]);
  const announcementBoardContractWithSigner = useMemo(() => announcementBoardContract.connect(signer), [announcementBoardContract, signer]);

  const [clanInfo, setClanInfo] = useState<ClanInfo | null>(null);
  const [clanMembers, setClanMembers] = useState<{ address: string; name: string; role: string }[]>([]);
  const [clanChat, setClanChat] = useState<{ sender: string; content: string }[]>([]);
  const [globalChat, setGlobalChat] = useState<{ sender: string; content: string }[]>([]);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [chatMessage, setChatMessage] = useState<string>('');
  const [isLeader, setIsLeader] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [chatTab, setChatTab] = useState<'clan' | 'global'>('clan');
  const [isNameLoaded, setIsNameLoaded] = useState<boolean>(false);
  const [warTargetClanId, setWarTargetClanId] = useState<number | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [inviteMemberAddress, setInviteMemberAddress] = useState<string>('');
  const [pendingApplications, setPendingApplications] = useState<{ address: string; name: string }[]>([]);

  const chatMessagesRef = useRef<HTMLDivElement>(null);

  const roles = useMemo(() => ['None', 'Owner', 'Leader', 'Commander', 'Lieutenant', 'Soldier'], []);

  const fetchClanInfo = useCallback(async () => {
    try {
      const clanData = await clanManagementReadContract.getClan(clanId) as ClanInfo;
      const membersAddresses = await clanManagementReadContract.getClanMembers(clanId);
      const pendingApplicationsAddresses = await clanManagementReadContract.getPendingApplications(clanId);
      const clanMessages = await chatManagementContract.getClanChat(clanId);
      const globalMessages = await chatManagementContract.getGlobalChat();

      const members = await Promise.all(membersAddresses.map(async (address: string) => {
        try {
          const isRegistered = await characterManagementContract.isNameRegistered(address);
          const name = isRegistered ? await characterManagementContract.getRegisteredName(address) : '';
          const roleIndex = await clanManagementReadContract.getClanRole(clanId, address);
          return { address, name, role: roles[roleIndex] || 'None' };
        } catch (err) {
          console.error(`Failed to fetch name for ${address}`, err);
          return { address, name: '', role: 'None' };
        }
      }));

      const applications = await Promise.all(pendingApplicationsAddresses.map(async (address: string) => {
        try {
          const isRegistered = await characterManagementContract.isNameRegistered(address);
          const name = isRegistered ? await characterManagementContract.getRegisteredName(address) : '';
          return { address, name };
        } catch (err) {
          console.error(`Failed to fetch name for ${address}`, err);
          return { address, name: '' };
        }
      }));

      setClanInfo(clanData);
      setClanMembers(members);
      setPendingApplications(applications);
      setClanChat(clanMessages);
      setGlobalChat(globalMessages);
      setIsLeader(clanData.leader.toLowerCase() === account.toLowerCase());

      const fetchedAnnouncements = await announcementBoardContract.getClanAnnouncements(clanId);
      setAnnouncements(fetchedAnnouncements);
      setIsNameLoaded(true);
    } catch (error) {
      console.error('Failed to fetch clan info:', error);
      toast.error('Failed to fetch clan info.');
    }
  }, [clanId, clanManagementReadContract, characterManagementContract, chatManagementContract, announcementBoardContract, account, roles]);

  useEffect(() => {
    fetchClanInfo();
  }, [fetchClanInfo]);

  useEffect(() => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  }, [clanChat, globalChat]);

  // Дебаунсирование обновления чата
  useEffect(() => {
    const chatUpdateInterval = setInterval(async () => {
      try {
        if (chatTab === 'clan') {
          const clanMessages = await chatManagementContract.getClanChat(clanId);
          setClanChat(clanMessages);
        } else {
          const globalMessages = await chatManagementContract.getGlobalChat();
          setGlobalChat(globalMessages);
        }
      } catch (error) {
        console.error('Failed to fetch chat messages:', error);
        toast.error('Failed to update chat.');
      }
    }, 30000); // Обновляем чат каждые 30 секунд

    return () => clearInterval(chatUpdateInterval);
  }, [chatTab, clanId, chatManagementContract]);

  const handleAssignRole = async (memberAddress: string, role: string) => {
    try {
      setIsLoading(true);
      const roleIndex = roles.indexOf(role);
      if (roleIndex === -1) {
        throw new Error('Invalid role');
      }
      const tx = await clanManagementContract.assignRole(clanId, memberAddress, roleIndex);
      await tx.wait();
      toast.success(`Role ${role} assigned to ${memberAddress}`);
      setClanMembers(prevMembers =>
        prevMembers.map(member =>
          member.address === memberAddress ? { ...member, role: role } : member
        )
      );
    } catch (error) {
      console.error('Failed to assign role:', error);
      toast.error('Failed to assign role.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeclareWar = async () => {
    if (warTargetClanId !== null) {
      try {
        setIsLoading(true);
        const tx = await clanManagementContract.declareWar(clanId, warTargetClanId);
        await tx.wait();
        toast.success(`War declared against clan ID: ${warTargetClanId}`);
      } catch (error) {
        console.error('Failed to declare war:', error);
        toast.error('Failed to declare war.');
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.warn('Please select a target clan ID.');
    }
  };

  const handleLeaveClan = async () => {
    try {
      setIsLoading(true);
      const tx = await clanManagementContract.leaveClan();
      await tx.wait();
      toast.success('You have left the clan.');
    } catch (error) {
      console.error('Failed to leave clan:', error);
      toast.error('Failed to leave clan.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpgradeClan = async () => {
    try {
      setIsLoading(true);
      const tx = await clanManagementContract.upgradeClan(clanId);
      await tx.wait();
      const updatedClanInfo = await clanManagementContract.getClan(clanId);
      setClanInfo(updatedClanInfo);
      toast.success('Clan upgraded successfully!');
    } catch (error) {
      console.error('Failed to upgrade clan:', error);
      toast.error('Failed to upgrade clan.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateClanImage = async () => {
    if (!imageFile) {
      toast.warn('Please select an image file to upload.');
      return;
    }
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        setIsLoading(true);
        try {
          const imageDataUrl = reader.result as string;
          const tx = await clanManagementContract.updateClanImage(clanId, imageDataUrl);
          await tx.wait();
          fetchClanInfo();
          toast.success('Clan image updated successfully!');
        } catch (error) {
          console.error('Failed to update clan image:', error);
          toast.error('Failed to update clan image.');
        } finally {
          setIsLoading(false);
        }
      };
      reader.readAsDataURL(imageFile);
    } catch (error) {
      console.error('Failed to read image file:', error);
      toast.error('Failed to process image file.');
    }
  };

  const handleInviteMember = async () => {
    if (!inviteMemberAddress) {
      toast.warn('Please enter a valid address.');
      return;
    }
    try {
      setIsLoading(true);
      const tx = await clanManagementContract.inviteMember(clanId, inviteMemberAddress);
      await tx.wait();
      toast.success('Invitation sent successfully!');
    } catch (error) {
      console.error('Failed to invite member:', error);
      toast.error('Failed to invite member.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (chatMessage.trim() && isNameLoaded) {
      try {
        setIsLoading(true);
        const senderName = registeredName || '';// Не показываем кошелек, если имя не загружено
        if (chatTab === 'clan') {
          const tx = await chatManagementContractWithSigner.sendMessageToClan(clanId, chatMessage);
          await tx.wait();
          setClanChat([...clanChat, { sender: senderName, content: chatMessage }]);
        } else {
          const tx = await chatManagementContractWithSigner.sendMessageToGlobal(chatMessage);
          await tx.wait();
          setGlobalChat([...globalChat, { sender: senderName, content: chatMessage }]);
        }
        setChatMessage('');
        toast.success('Message sent successfully!');
      } catch (error) {
        console.error('Failed to send message:', error);
        toast.error('Failed to send message.');
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.warn('Please enter a message.');
    }
  };

  const handleKeyPress = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      await handleSendMessage();
    }
  };

  const handlePostAnnouncement = async () => {
    if (chatMessage.trim() && isNameLoaded) {
      try {
        setIsLoading(true);
        const senderName = registeredName || ''; // Не показываем кошелек, если имя не загружено
        const tx = await announcementBoardContractWithSigner.postAnnouncement(clanId, account, chatMessage);
        await tx.wait();
        setAnnouncements([
          ...announcements,
          { sender: senderName, content: chatMessage, timestamp: Math.floor(Date.now() / 1000) },
        ]);
        setChatMessage('');
        toast.success('Announcement posted successfully!');
      } catch (error) {
        console.error('Failed to post announcement:', error);
        toast.error('Failed to post announcement.');
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.warn('Please enter an announcement.');
    }
  };

  const handlePinAnnouncement = (index: number) => {
    setAnnouncements(prevAnnouncements =>
      prevAnnouncements.map((announcement, i) => ({
        ...announcement,
        pinned: i === index ? !announcement.pinned : announcement.pinned, // Закрепляем или открепляем сообщение
      }))
    );
  };

  const formatBigNumber = (value: BigNumber): string => {
    return value.toString();
  };

  const renderContentWithLinks = (content: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return content.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    <div className="clan-dashboard-container">
      <ToastContainer />
      <div className="clan-dashboard-main">
        <div className="clan-info-and-announcements">
          {/* Секция с информацией о клане */}
          <div className="clan-info-section">
            {clanInfo ? (
              <>
                <div className="clan-info-top">
                  <div className="clan-info-left">
                    <img src={clanInfo.image} alt="Clan Logo" className="clan-logo" />
                    <div className="clan-details">
                      <div>Clan: {clanInfo.name}</div>
                      <div>Owner: {clanInfo.leader}</div>
                      <div>Experience: {formatBigNumber(clanInfo.experience)}</div>
                      <div>Lvl: {formatBigNumber(clanInfo.level)}</div>
                    </div>
                  </div>
                </div>
                <div className="clan-info-bottom">
                  <div className="clan-members-section">
                    <div className="clan-members-list">
                      <div className="clan-members-header">
                        <div className="clan-members-title">Main Clan - {clanInfo.name}</div>
                        <div className="clan-members-count">({clanMembers.length}/80)</div>
                      </div>
                      <div className="clan-members-table-wrapper">
                        <table className="clan-members-table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Lvl.</th>
                              <th>Role</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {clanMembers.slice(0, 10).map((member, index) => (
                              <tr key={index}>
                                <td>{member.name || 'Unknown'}</td>
                                <td>{formatBigNumber(clanInfo.level)}</td>
                                <td>
                                  <select
                                    value={member.role}
                                    onChange={(e) => handleAssignRole(member.address, e.target.value)}
                                    disabled={!isLeader || isLoading}
                                  >
                                    {roles.map((role, idx) => (
                                      <option key={idx} value={role}>
                                        {role}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td><span className="status-icon">⚔️</span></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="pending-applications">
                      <h3>Pending Applications</h3>
                      <table className="clan-members-table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pendingApplications.map((applicant, index) => (
                            <tr key={index}>
                              <td>{applicant.name || 'Unknown'}</td>
                              <td>{applicant.address}</td>
                              <td>
                                <button
                                  onClick={async () => {
                                    try {
                                      setIsLoading(true);
                                      const tx = await clanManagementContract.acceptApplication(clanId, applicant.address);
                                      await tx.wait();
                                      toast.success(`${applicant.name} has been accepted into the clan!`);
                                      fetchClanInfo();
                                    } catch (error) {
                                      console.error('Failed to accept application:', error);
                                      toast.error('Failed to accept application.');
                                    } finally {
                                      setIsLoading(false);
                                    }}
                                  }
                                  disabled={isLoading}
                                >
                                  Accept
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="clan-actions">
                    <input
                      type="number"
                      placeholder="Enter Target Clan ID"
                      value={warTargetClanId ?? ''}
                      onChange={(e) => setWarTargetClanId(Number(e.target.value))}
                      disabled={isLoading}
                    />
                    <button onClick={handleDeclareWar} disabled={isLoading}>Declare War</button>
                    <button onClick={handleLeaveClan} disabled={isLoading}>Leave Clan</button>
                    <button onClick={handleUpgradeClan} disabled={isLoading}>Upgrade Clan</button>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setImageFile(e.target.files?.[0] || null)}
                      disabled={isLoading}
                    />
                    <button onClick={handleUpdateClanImage} disabled={isLoading}>Update Clan Image</button>
                    <input
                      type="text"
                      placeholder="Invite member by address"
                      value={inviteMemberAddress}
                      onChange={(e) => setInviteMemberAddress(e.target.value)}
                      disabled={isLoading}
                    />
                    <button onClick={handleInviteMember} disabled={isLoading}>Invite Member</button>
                  </div>
                </div>
              </>
            ) : (
              <p>Loading clan information...</p>
            )}
          </div>

          {/* Секция с доской объявлений */}
          <div className="announcement-board">
            <h3>Clan Announcements</h3>
            <div className="announcement-content">
              {isNameLoaded ? (
                announcements.length === 0 ? (
                  <p>No announcements yet.</p>
                ) : (
                  [...announcements]
                  .sort((a, b) => (b.pinned ? 1 : -1) - (a.pinned ? 1 : -1)) // Закрепленные сообщения наверху
                  .map((announcement, index) => (
                    <div key={index}>
                      <p><strong>{announcement.sender || 'Unknown'}</strong>: {renderContentWithLinks(announcement.content)}</p>
                      <p>{new Date(announcement.timestamp * 1000).toLocaleString()}</p>
                      {isLeader && (
                        <button onClick={() => handlePinAnnouncement(index)}>
                          {announcement.pinned ? 'Unpin' : 'Pin'}
                        </button>
                      )}
                    </div>
                  ))
                
                )
              ) : (
                <p>Loading...</p>
              )}
            </div>
            {isLeader && (
              <>
                <textarea
                  id="announcementBoard"
                  value={chatMessage}
                  onChange={(e) => setChatMessage(e.target.value)}
                  disabled={!isLeader || isLoading}
                  placeholder="Write an announcement..."
                />
                <button onClick={handlePostAnnouncement} disabled={isLoading}>Post Announcement</button>
              </>
            )}
          </div>
        </div>

        {/* Секция с чатом */}
        <div className="clan-chat">
          <div className="chat-tabs">
            <button onClick={() => setChatTab('clan')} disabled={chatTab === 'clan'}>Clan Chat</button>
            <button onClick={() => setChatTab('global')} disabled={chatTab === 'global'}>Global Chat</button>
          </div>
          <div className="chat-messages" ref={chatMessagesRef}>
            {isNameLoaded ? (
              (chatTab === 'clan' ? clanChat : globalChat).map((message, index) => (
                <p key={index}><strong>{message.sender || 'Unknown'}</strong>: {message.content}</p>
              ))
            ) : (
              <p>Loading...</p>
            )}
          </div>
          <input
            type="text"
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
            placeholder="Type a message..."
            onKeyPress={handleKeyPress}
            disabled={isLoading}
          />
          <button onClick={handleSendMessage} disabled={isLoading}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default ClanDashboard;
