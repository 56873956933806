import React from 'react';
import '../styles/WhitePaperPage.css';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const tokenomicsData = [
  { name: 'Public Sale', value: 50, color: '#ff4500' },
  { name: 'Airdrop', value: 3, color: '#00bfff' },
  { name: 'Staking', value: 20, color: '#32cd32' },
  { name: 'Developers', value: 10, color: '#8a2be2' },
  { name: 'Marketing', value: 10, color: '#ffa500' },
  { name: 'Reserve', value: 5, color: '#ff69b4' },
  { name: 'Community', value: 2, color: '#20b2aa' },
];

const roadmapData = [
  { quarter: 'Q3 2024', events: ['Start of project development and concept creation.'] },
  { quarter: 'Q4 2024', events: ['Development of smart contracts for managing NFTs, duels, staking, chat, and marketplace.', 'Launch of the test website and start of beta testing.'] },
  { quarter: 'Q1 2025', events: ['Opening of the public token sale.', 'Start of the marketing campaign.'] },
  { quarter: 'Q2 2025', events: ['Launch of the full version of Protonia.net platform.', 'Integration of rare and unique NFT avatars.', 'Launch of the marketplace and duel lobby.'] },
  { quarter: 'Q3 2025', events: ['Addition of new races and game improvements.', 'Expansion of duel and quest functionality.'] },
  { quarter: 'Q4 2025', events: ['Integration with new partners and exchanges.', 'Organization of the first tournaments and events.', 'Year-end review and planning for future project development.'] },
];

const WhitePaperPage: React.FC = () => {
  return (
    <div className="whitepaper-container">
      <h1 className="whitepaper-title">Protonia Whitepaper</h1>

      <section className="whitepaper-section">
        <h2>Introduction</h2>
        <p>
          Protonia is a decentralized gaming platform where users can participate in various activities, earn rewards, and engage in exciting duels. This whitepaper provides an overview of our platform, its features, and the economic model supporting the game.
        </p>
      </section>

      <section className="whitepaper-section tokenomics-section">
        <h2>Tokenomics</h2>
        <div className="tokenomics-content">
          <div className="tokenomics-chart">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={tokenomicsData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                >
                  {tokenomicsData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <ul className="tokenomics-info">
            {tokenomicsData.map((entry, index) => (
              <li key={index} style={{ color: entry.color }}>
                {entry.name}: {entry.value}%
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="whitepaper-section">
        <h2>Character Management</h2>
        <p>
          The <strong>CharacterManagement</strong> contract is the core of Protonia's gameplay. It manages the creation, customization, and evolution of characters. Each character has attributes such as strength, health, and experience, and can participate in duels or missions to earn rewards and level up.
        </p>
        <p>Key functionalities include:</p>
        <ul>
          <li><strong>Character Creation:</strong> Players can create characters with different races and rarities, each having unique attributes. The contract allows the creation of a wide range of characters, offering diversity in gameplay.</li>
          <li><strong>Health Management:</strong> The contract manages health recovery over time, ensuring characters regenerate health based on their activities and in-game events.</li>
          <li><strong>Duels:</strong> Characters can engage in duels, where they wager tokens, use strategic attacks and defenses, and can win rewards. The duel system is balanced with timeout features to ensure fair play.</li>
          <li><strong>Leveling System:</strong> Characters gain experience from duels and missions, which allows them to level up and increase their attributes like strength and health.</li>
          <li><strong>Evolution:</strong> Characters can evolve to higher rarities through the use of tokens and Ether, enhancing their abilities and competitiveness in the game.</li>
          <li><strong>Inventory Management:</strong> Characters have an inventory of items that they can use to improve their attributes. The inventory size is limited, requiring strategic management of items.</li>
          <li><strong>Duel Management:</strong> The contract handles duel requests, duel initiation, action submission, and the resolution of duels, including timeout checks and the allocation of rewards.</li>
        </ul>
      </section>

      <section className="whitepaper-section">
        <h2>Item Management</h2>
        <p>
          The <strong>ItemManagement</strong> contract allows for the creation and management of in-game items. These items provide bonuses to character attributes such as strength, health, and experience. Items can be crafted, equipped, and traded in the marketplace.
        </p>
        <p>Items are categorized into:</p>
        <ul>
          <li><strong>Weapons:</strong> Increase character's strength and attack power in duels.</li>
          <li><strong>Armor:</strong> Enhance character's defense and health, making them more resilient in battles.</li>
          <li><strong>Accessories:</strong> Provide various bonuses, including experience boosts and special abilities.</li>
        </ul>
      </section>

      <section className="whitepaper-section">
        <h2>Advanced Gameplay Features</h2>
        <p>Several advanced features enhance the gameplay experience in Protonia:</p>
        <h3>Achievement Management</h3>
        <p>
          The <strong>AchievementManagement</strong> contract tracks and rewards players for their in-game accomplishments. Players can earn tokens or items as rewards for completing specific achievements.
        </p>

        <h3>Event Management</h3>
        <p>
          The <strong>EventManagement</strong> contract allows for the creation and participation in time-limited events. Players can join events for a fee, and winners receive rewards from the prize pool.
        </p>

        <h3>Referral Management</h3>
        <p>
          The <strong>ReferralManagement</strong> contract incentivizes players to invite others to the platform. Referrers earn rewards when their referees achieve specific milestones.
        </p>
      </section>

      <section className="whitepaper-section">
        <h2>Clan and Social Features</h2>
        <p>Protonia emphasizes community interaction through the following contracts:</p>
        <h3>Clan Management</h3>
        <p>
          The <strong>ClanManagement</strong> contract allows players to create and manage clans. Clans can compete against each other in wars, purchase buffs, and manage their members.
        </p>

        <h3>Chat Management</h3>
        <p>
          The <strong>ChatManagement</strong> contract provides in-game communication. Players can send messages within their clan or globally, with all chat fees contributing to the game's liquidity pool.
        </p>
      </section>

      <section className="whitepaper-section">
        <h2>Spaceship and Staking</h2>
        <p>
          The <strong>SpaceShipManager</strong> contract allows players to mint, upgrade, and manage spaceships. Spaceships offer various bonuses depending on their rarity and can be staked for rewards.
        </p>
        <p>
          The <strong>StakingManager</strong> contract manages the staking of spaceships. Players can stake their spaceships on different planets to earn rewards over time. The rewards depend on the planet's characteristics and the spaceship's rarity.
        </p>
      </section>

      <section className="whitepaper-section">
        <h2>Token and Public Sale</h2>
        <p>
          The <strong>ProtoniaToken</strong> is the primary currency within the Protonia platform. It is used for all transactions, including purchasing items, entering duels, and participating in events.
        </p>
        <p>
          The <strong>PublicSale</strong> contract manages the initial distribution of tokens through a public sale. Users can purchase tokens, and a portion of the proceeds is allocated to liquidity and development.
        </p>
      </section>

      <section className="whitepaper-section">
        <h2>Marketplace</h2>
        <p>
          The <strong>Marketplace</strong> contract allows players to buy and sell characters, items, and other in-game assets. Only characters of level 5 and above can be listed for sale, ensuring that the marketplace remains competitive and fair.
        </p>
      </section>

      <section className="whitepaper-section">
        <h2>PvE and Evolution</h2>
        <p>
          The <strong>PvEManagement</strong> contract provides a way for players to engage in battles against AI opponents. These battles allow characters to gain experience and rewards, contributing to their growth and evolution.
        </p>
        <p>
          The <strong>EvolutionManagement</strong> contract enables players to evolve their characters to higher rarities by spending tokens and Ether. Evolution increases the character's abilities and makes them more competitive in duels and events.
        </p>
      </section>

      <section className="whitepaper-section">
        <h2>Talent Management</h2>
        <p>
          The <strong>TalentManagement</strong> contract allows characters to learn and upgrade talents, providing them with additional skills and bonuses. Talents can be improved over time, making characters more versatile and powerful.
        </p>
      </section>

      <section className="whitepaper-section">
        <h2>Roadmap</h2>
        <div className="roadmap-timeline">
          {roadmapData.map((item, index) => (
            <div key={index} className={`roadmap-quarter ${item.quarter === 'Q4 2024' ? 'current' : ''}`}>
              <h3>{item.quarter}</h3>
              <ul>
                {item.events.map((event, idx) => (
                  <li key={idx}>{event}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>

      <section className="whitepaper-section">
        <h2>Conclusion</h2>
        <p>
          Protonia offers a unique and engaging experience for all participants, allowing users to interact with the platform, earn rewards, and compete in exciting activities. Join us on this journey and become a part of the Protonia community!
        </p>
      </section>
    </div>
  );
};

export default WhitePaperPage;
