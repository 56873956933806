import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import "./styles/App.css";
import { usePrivy, useWallets } from "@privy-io/react-auth";
import { ethers } from 'ethers';
import WalletInfo from './components/WalletInfo';
import Planets from './components/Planets';
import CharacterManagementABI from './abis/CharacterManagement.json';

import LoginPage from './components/LoginPage';
import Footer from './components/Footer';
import WhitePaperPage from './components/WhitePaperPage';
import ProfilePage from './components/ProfilePage';
import ClanManagement from './components/ClanManagement';
import MainPage from './components/MainPage';
import GalaxyPage from './components/GalaxyPage';
import DuelLobby from './components/DuelLobby';
import DuelFightPage from './components/DuelFightPage';

const CONTRACT_ADDRESS = '0x7f21801F46139b6c0aa6840036BA5Fb6e23f7E26';
const BASE_NETWORK_ID = 8453;

const NETWORKS: { [key: number]: string } = {
  1: 'Ethereum Mainnet',
  8453: 'Base Mainnet',
};

function App() {
  const { ready, authenticated, login, logout } = usePrivy();
  const { wallets } = useWallets();
  const [registeredName, setRegisteredName] = useState<string | null>(localStorage.getItem('registeredName'));
  const [currentAddress, setCurrentAddress] = useState<string | null>(localStorage.getItem('currentAddress'));
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [showNetworkWarning, setShowNetworkWarning] = useState<boolean>(false);
  const [networkName, setNetworkName] = useState<string | null>(null);
  const [showWalletInfo, setShowWalletInfo] = useState<boolean>(false);

  const closeNetworkWarning = () => setShowNetworkWarning(false);

  const fetchRegisteredName = useCallback(async (ethersProvider: ethers.providers.Web3Provider, address: string) => {
    try {
      const contract = new ethers.Contract(CONTRACT_ADDRESS, CharacterManagementABI, ethersProvider);
      const name = await contract.getRegisteredName(address);
      setRegisteredName(name || null);
      localStorage.setItem('registeredName', name || '');
    } catch (contractError) {
      console.error("Contract call failed:", contractError);
    }
  }, []);

  const initializeProvider = useCallback(async () => {
    try {
      if (wallets && wallets.length > 0) {
        const wallet = wallets[0];
        const ethProvider = await wallet.getEthereumProvider();

        if (!ethProvider) {
          throw new Error("Failed to get Ethereum provider from wallet.");
        }

        const ethersProvider = new ethers.providers.Web3Provider(ethProvider as ethers.providers.ExternalProvider);

        await ethersProvider.send("eth_requestAccounts", []);
        const accounts = await ethersProvider.listAccounts();
        if (accounts.length === 0) {
          throw new Error("No accounts found in provider");
        }

        const address = accounts[0];
        const network = await ethersProvider.getNetwork();
        const networkName = NETWORKS[network.chainId] || `Unknown (${network.chainId})`;

        setProvider(ethersProvider);
        setCurrentAddress(address);
        setShowNetworkWarning(network.chainId !== BASE_NETWORK_ID);
        setNetworkName(networkName);

        localStorage.setItem('currentAddress', address);
        localStorage.setItem('networkName', networkName);

        fetchRegisteredName(ethersProvider, address);
      }
    } catch (error) {
      console.error("Failed to initialize provider:", error);
    }
  }, [wallets, fetchRegisteredName]);

  useEffect(() => {
    if (authenticated) {
      initializeProvider();
    }
  }, [authenticated, initializeProvider]);

  const handleLogout = async () => {
    try {
      await logout();
      setRegisteredName(null);
      setCurrentAddress(null);
      setProvider(null);
      setShowWalletInfo(false);
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <Router>
      <div className="App">
        <Planets />
        <header className="App-header">
          <Link to="/" className="logo-container">
            <img src="/logo.png" alt="Protonia Logo" className="logo" />
            <span className="brand-name">Protonia</span>
          </Link>
          <div className="nav-links-container">
            <Link to="/profile" className="nav-link">Profile</Link>
            <Link to="/galaxy" className="nav-link">Galaxy</Link>
            <Link to="/duel" className="nav-link">Duel</Link>
            <Link to="/clans" className="nav-link">Clans</Link>
            <Link to="/whitepaper" className="nav-link">White Paper</Link>
          </div>
          <div className="user-info-container">
            {!authenticated || !currentAddress ? (
              <button onClick={login} className="login-button">
                Connect Wallet
              </button>
            ) : (
              <>
                {registeredName && <span className="user-name">{registeredName}</span>}
                <button onClick={() => setShowWalletInfo(!showWalletInfo)} className="login-button">
                  {showWalletInfo ? 'Hide Wallet Info' : 'Wallet Info'}
                </button>
              </>
            )}
          </div>
        </header>

        <div className="App-content">
          {showNetworkWarning && (
            <div className="network-warning">
              <p>You are connected to the wrong network ({networkName || "Unknown"}). Please switch to the Base network.</p>
              <button onClick={closeNetworkWarning} className="close-warning-button">
                Close
              </button>
            </div>
          )}
          {showWalletInfo && provider && currentAddress && (
            <div className="wallet-info-container">
              <WalletInfo provider={provider} address={currentAddress} onLogout={handleLogout} />
            </div>
          )}
          <Routes>
            <Route path="/whitepaper" element={<WhitePaperPage />} />
            <Route
              path="/login"
              element={
                !authenticated || !registeredName ? (
                  <LoginPage currentAddress={currentAddress} provider={provider} />
                ) : (
                  <LoginPage currentAddress={currentAddress} provider={provider} />
                )
              }
            />
            <Route
              path="/profile"
              element={
                authenticated && provider && currentAddress && registeredName ? (
                  <ProfilePage
                    account={currentAddress}
                    provider={provider}
                    characterManagementAddress={CONTRACT_ADDRESS}
                  />
                ) : (
                  <LoginPage currentAddress={currentAddress} provider={provider} />
                )
              }
            />
            <Route
              path="/clans"
              element={
                authenticated && provider && currentAddress && registeredName ? (
                  <ClanManagement
                    provider={provider}
                    account={currentAddress}
                    clanCreationFee="0.00001"
                    registeredName={registeredName}
                    setRegisteredName={setRegisteredName}
                  />
                ) : (
                  <LoginPage currentAddress={currentAddress} provider={provider} />
                )
              }
            />
            <Route
              path="/galaxy"
              element={
                authenticated && provider && currentAddress && registeredName ? (
                  <GalaxyPage
                    account={currentAddress}
                    provider={provider}
                    registeredName={registeredName}
                  />
                ) : (
                  <LoginPage currentAddress={currentAddress} provider={provider} />
                )
              }
            />
            <Route
              path="/duel"
              element={
                authenticated && provider && currentAddress && registeredName ? (
                  <DuelLobby
                    provider={provider}
                    account={currentAddress || ''}
                    duelContractAddress="0xC18AB061d26862f0a4eb84cF51660F222f60E999"
                    characterManagementAddress={CONTRACT_ADDRESS}
                    tokenAddress="0x52941A594eb3f57ceB88717aa4190EEf5da10f6D" internalProvider={null}                />
                ) : (
                  <LoginPage currentAddress={currentAddress} provider={provider} />
                )
              }
            />

            <Route
              path="/duel/:duelId"
              element={
                authenticated && provider && currentAddress && registeredName ? (
                  <DuelFightPage
                  provider={provider}
                  internalProvider={null} // Или передайте другой провайдер при необходимости
                  account={currentAddress}
                  />
                ) : (
                  <LoginPage currentAddress={currentAddress} provider={provider} />
                )
              }
            />
            <Route
              path="/"
              element={
                authenticated ? (
                  registeredName ? (
                    <MainPage
                      account={currentAddress}
                      provider={provider}
                      registeredName={registeredName}
                      setRegisteredName={setRegisteredName}
                    />
                  ) : (
                    <LoginPage currentAddress={currentAddress} provider={provider} />
                  )
                ) : (
                  <LoginPage currentAddress={currentAddress} provider={provider} />
                )
              }
            />
            <Route path="*" element={<LoginPage currentAddress={currentAddress} provider={provider} />} />
          </Routes>
        </div>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
