import React, { useEffect, useState, useCallback } from 'react';
import { ethers } from 'ethers';
import tokenAbi from '../abis/ProtoniaToken.json';
import '../styles/WalletInfo.css'; 

interface WalletInfoProps {
  provider: ethers.providers.Web3Provider | null;
  address: string | null;
  onLogout: () => void;
}

const WalletInfo: React.FC<WalletInfoProps> = ({ provider, address, onLogout }) => {
  const [recipientAddress, setRecipientAddress] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [transactionHash, setTransactionHash] = useState<string | null>(null);
  const [networkName, setNetworkName] = useState<string | null>(null);
  const [ethBalance, setEthBalance] = useState<string | null>(null);
  const [tokenBalance, setTokenBalance] = useState<string | null>(null);
  const [selectedToken, setSelectedToken] = useState<string>('ETH');

  const TOKEN_ADDRESS = '0x52941A594eb3f57ceB88717aa4190EEf5da10f6D';

  const fetchBalances = useCallback(async () => {
    if (provider && address) {
      try {
        const network = await provider.getNetwork();
        const networkNames: { [key: number]: string } = {
          1: 'Ethereum Mainnet',
          8453: 'Base Mainnet',
        };
  
        // Проверка, что сеть не изменилась
        if (network.chainId !== provider.network?.chainId) {
          console.warn('Network changed during the request');
          return;
        }
  
        setNetworkName(networkNames[network.chainId] || `Unknown (${network.chainId})`);
  
        const balance = await provider.getBalance(address);
        setEthBalance(ethers.utils.formatEther(balance));
  
        if (network.chainId === 8453) {
          const tokenContract = new ethers.Contract(TOKEN_ADDRESS, tokenAbi, provider);
          const tokenBalance = await tokenContract.balanceOf(address);
          setTokenBalance(ethers.utils.formatEther(tokenBalance));
        } else {
          setTokenBalance(null);  // Обнулить баланс токенов, если сеть не поддерживает токен
        }
      } catch (error) {
        if (typeof error === 'object' && error !== null && 'code' in error) {
          const typedError = error as { code: string };
          
          if (typedError.code === 'NETWORK_ERROR') {
            console.error('Network error detected. It seems the network changed during the request:', typedError);
            // Опционально: повторить запрос или обновить состояние
          } else {
            console.error('Failed to fetch balances:', typedError);
          }
        } else {
          console.error('An unknown error occurred:', error);
        }
      }
      
      
    }
  }, [provider, address]);
  

  const delayedFetchBalances = useCallback(() => {
    setTimeout(fetchBalances, 1500); // Задержка 1,5 секунды перед повторным получением балансов после переключения сети
  }, [fetchBalances]);

  useEffect(() => {
    const handleNetworkChange = async (chainId: string) => {
      console.log(`Network changed to ${chainId}`);
      setEthBalance(null);
      setTokenBalance(null);
      setNetworkName(null);

      setTimeout(() => {
        fetchBalances();  // Повторная инициализация провайдера
      }, 1000);
    };

    if (provider?.on) {
      provider.on('network', handleNetworkChange);
    }

    fetchBalances();

    return () => {
      if (provider?.removeListener) {
        provider.removeListener('network', handleNetworkChange);
      }
    };
  }, [provider, address, fetchBalances]);

  const sendTokens = async () => {
    if (provider && address) {
      try {
        const signer = provider.getSigner();
        let tx: ethers.providers.TransactionResponse;
  
        // Убедитесь, что recipientAddress является валидным адресом, а не ENS-именем
        if (!ethers.utils.isAddress(recipientAddress)) {
          throw new Error('Invalid recipient address. Please enter a valid Ethereum address.');
        }
  
        if (selectedToken === 'ETH') {
          tx = await signer.sendTransaction({
            to: recipientAddress,
            value: ethers.utils.parseEther(amount),
          });
        } else {
          const tokenContract = new ethers.Contract(TOKEN_ADDRESS, tokenAbi, signer);
          tx = await tokenContract.transfer(recipientAddress, ethers.utils.parseUnits(amount, 18));
        }
  
        setTransactionHash(tx.hash);
        const receipt = await tx.wait();
        console.log('Transaction receipt:', receipt);
      } catch (error) {
        console.error('Failed to send transaction:', error);
      }
    }
  };
  

  const switchNetwork = async (chainId: string) => {
    if (provider && provider.provider?.request) {
      try {
        await provider.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
  
        const networkNames: { [key: number]: string } = {
          1: 'Ethereum Mainnet',
          8453: 'Base Mainnet',
        };
        setNetworkName(networkNames[parseInt(chainId, 16)] || `Unknown (${chainId})`);
  
        // Задержка перед повторным получением балансов после переключения сети
        delayedFetchBalances();
      } catch (error) {
        console.error('Failed to switch network:', error);
      }
    }
  };
  
  return (
    <div className="wallet-info-container">
      <h3>Wallet Information</h3>
      <p>Address: {address}</p>
      <p>Network: {networkName}</p>
      <p>ETH Balance: {ethBalance}</p>
      <p>PRTN Balance: {tokenBalance}</p>

      <h4>Send Tokens</h4>
      <select value={selectedToken} onChange={(e) => setSelectedToken(e.target.value)}>
        <option value="ETH">ETH</option>
        <option value="TOKEN">PRTN</option>
      </select>
      <input
        type="text"
        placeholder="Recipient Address"
        value={recipientAddress}
        onChange={(e) => setRecipientAddress(e.target.value)}
      />
      <input
        type="text"
        placeholder="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <button onClick={sendTokens}>Send</button>

      {transactionHash && (
        <p>Transaction sent! Hash: <a href={`https://basescan.org/tx/${transactionHash}`} target="_blank" rel="noopener noreferrer">{transactionHash}</a></p>
      )}

      <h4>Switch Network</h4>
      <button onClick={() => switchNetwork('0x1')}>Switch to Ethereum Mainnet</button>
      <button onClick={() => switchNetwork('0x2105')}>Switch to Base Mainnet</button>

      <button onClick={onLogout} className="disconnect-button">Disconnect</button>
    </div>
  );
};

export default WalletInfo;
