import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PrivyProvider } from '@privy-io/react-auth';
import { base, mainnet } from 'viem/chains';
import { addRpcUrlOverrideToChain } from '@privy-io/react-auth';

const baseOverride = addRpcUrlOverrideToChain(base, 'https://base-mainnet.g.alchemy.com/v2/Ff4oIvuz9TxRHtb5sBKb_2GQXzCNC0RY');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <PrivyProvider
      appId="clzzypmlp00txoddur4ggx1ty"
      config={{
        loginMethods: ['email', 'wallet'],
        appearance: {
          theme: '#000000',
          accentColor: '#6A6FF5',
          showWalletLoginFirst: false,
          logo: 'https://blush-fancy-grasshopper-497.mypinata.cloud/ipfs/QmcAMYhwEWT4psK68JxBHbBy169CuPYQ6i9x7pU3J8RGZm',
        },
        fundingMethodConfig: {
          moonpay: {
            useSandbox: true,
          },
        },
        embeddedWallets: {
          createOnLogin: 'users-without-wallets',
          requireUserPasswordOnCreate: true,
        },
        mfa: {
          noPromptOnMfaRequired: false,
        },
        externalWallets: {
          coinbaseWallet: {
            connectionOptions: 'all',
          },
        },
        defaultChain: baseOverride,
        supportedChains: [baseOverride, mainnet],
      }}
    >
      <App />
    </PrivyProvider>
  </React.StrictMode>,
);

reportWebVitals();
