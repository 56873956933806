import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import '../styles/SpaceShipManager.css';

interface SpaceShipManagerProps {
  provider: ethers.providers.Web3Provider | null;
  account: string;
  spaceShipManagerContract: ethers.Contract;
}

interface Ship {
  id: number;
  name: string;
  rarity: string;
  bonus: number;
}

const SpaceShipManager: React.FC<SpaceShipManagerProps> = ({ provider, account, spaceShipManagerContract }) => {
  const [shipPrices, setShipPrices] = useState<{ [key: string]: string }>({});
  const [userShips, setUserShips] = useState<Ship[]>([]);
  const [selectedRarity, setSelectedRarity] = useState<string>('Common');

  const fetchShipPrices = useCallback(async () => {
    const rarities = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'];
    const prices: { [key: string]: string } = {};
    for (const rarity of rarities) {
      const price = await spaceShipManagerContract.shipPrices(rarities.indexOf(rarity));
      prices[rarity] = ethers.utils.formatEther(price);
    }
    setShipPrices(prices);
  }, [spaceShipManagerContract]);

  useEffect(() => {
    fetchShipPrices();
  }, [fetchShipPrices]);

  const fetchUserShips = useCallback(async () => {
    try {
      const shipIds = await spaceShipManagerContract.getOwnedShips(account);
      const ships: Ship[] = [];

      for (const shipId of shipIds) {
        const rarity = await spaceShipManagerContract.getShipRarity(shipId);
        const bonus = await spaceShipManagerContract.getShipBonus(shipId);
        const name = await spaceShipManagerContract.getShipName(shipId);

        ships.push({
          id: shipId.toNumber(),
          name,
          rarity: ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'][rarity],
          bonus: bonus.toNumber(),
        });
      }

      setUserShips(ships);
    } catch (error) {
      console.error('Failed to fetch user ships:', error);
    }
  }, [spaceShipManagerContract, account]);

  useEffect(() => {
    fetchUserShips();
  }, [fetchUserShips]);

  const handleBuyShip = async () => {
    if (!provider) return;

    try {
      const signer = provider.getSigner(); // Получаем подписанта
      const contractWithSigner = spaceShipManagerContract.connect(signer); // Привязываем контракт к подписанту

      const rarityIndex = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'].indexOf(selectedRarity);
      const price = shipPrices[selectedRarity];
      if (!price) throw new Error('Price not available for selected rarity');

      const tx = await contractWithSigner.buyShip(rarityIndex, {
        value: ethers.utils.parseEther(price),
      });
      await tx.wait();
      alert('Ship purchased successfully!');
      fetchUserShips(); // Refresh user's ships after purchase
    } catch (error) {
      console.error('Failed to purchase ship:', error);
      alert('Failed to purchase ship. See console for details.');
    }
  };

  return (
    <div className="space-ship-manager">
      <h2>Buy your Ship for Staking</h2>
      <div className="ship-purchase">
        <label htmlFor="rarity-select">Choose the rarity:</label>
        <select id="rarity-select" name="rarity" value={selectedRarity} onChange={(e) => setSelectedRarity(e.target.value)}>
          <option value="Common">Common</option>
          <option value="Uncommon">Uncommon</option>
          <option value="Rare">Rare</option>
          <option value="Epic">Epic</option>
          <option value="Legendary">Legendary</option>
        </select>
        <p>Price: {shipPrices[selectedRarity] || 'N/A'} ETH</p>
        <button onClick={handleBuyShip}>Buy Ship</button>
      </div>

      <h3>Your Ships</h3>
      <ul>
        {userShips.map((ship) => (
          <li key={ship.id}>
            {ship.name} (Rarity: {ship.rarity}, Bonus: {ship.bonus}%)
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SpaceShipManager;
