import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import '../styles/CharacterSale.css';

interface CharacterSaleProps {
  characterManagementContract: ethers.Contract | null;
  provider: ethers.providers.Web3Provider | null;
  account: string;
}

const CharacterSale: React.FC<CharacterSaleProps> = ({
  characterManagementContract,
  provider,
  account,
}) => {
  const [selectedRarity, setSelectedRarity] = useState<number>(0);
  const [selectedRace, setSelectedRace] = useState<string>('EarthMan');
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [accountName, setAccountName] = useState<string | null>(null);

  const rarities = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'];
  const prices = [
    ethers.utils.parseEther('0.00001'),
    ethers.utils.parseEther('0.00002'),
    ethers.utils.parseEther('0.00003'),
    ethers.utils.parseEther('0.00004'),
    ethers.utils.parseEther('0.00005'),
  ];

  const races = [
    'EarthMan', 'Martian', 'Venusian', 'Jovian', 'Saturnian', 
    'Uranian', 'Neptunian', 'Plutonian', 'Andromedan', 
    'Centaurian', 'Draconian', 'Feline', 'Canine', 'Reptilian', 
    'Avian', 'Insectoid', 'Aquatic', 'Pyronian', 'Terran', 'Cybernetic'
  ];

  useEffect(() => {
    const fetchAccountName = async () => {
      if (!characterManagementContract) return;

      try {
        const name = await characterManagementContract.addressToName(account);
        if (name && name !== "") {
          setAccountName(name);
        } else {
          setMessage('No name registered for this account. Please register a name first.');
        }
      } catch (error) {
        console.error('Failed to fetch account name:', error);
        setMessage('Failed to fetch account name.');
      }
    };

    fetchAccountName();
  }, [characterManagementContract, account]);

  const handlePurchase = async () => {
    if (!characterManagementContract || !provider) {
      setMessage('Please connect your wallet.');
      return;
    }

    if (!accountName) {
      setMessage('No name registered for this account.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      const signer = provider.getSigner(); // Получаем подписанта
      const contractWithSigner = characterManagementContract.connect(signer); // Привязываем контракт к подписанту

      const tx = await contractWithSigner.buyCharacter(accountName, selectedRarity, selectedRace, {
        value: prices[selectedRarity]
      });

      await tx.wait();
      setMessage('Character purchased successfully!');
    } catch (error) {
      console.error('Purchase failed', error);
      setMessage('Purchase failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="character-sale">
      <h2>Buy a New Character</h2>
      {accountName ? (
        <>
          <div className="rarity-selection">
            <label htmlFor="rarity-select">Select Rarity:</label>
            <select
              id="rarity-select"
              name="rarity"
              value={selectedRarity}
              onChange={(e) => setSelectedRarity(Number(e.target.value))}
              disabled={loading}
            >
              {rarities.map((rarity, index) => (
                <option key={index} value={index}>
                  {rarity} - {ethers.utils.formatEther(prices[index])} ETH
                </option>
              ))}
            </select>
          </div>
          <div className="race-selection">
            <label htmlFor="race-select">Select Race:</label>
            <select
              id="race-select"
              name="race"
              value={selectedRace}
              onChange={(e) => setSelectedRace(e.target.value)}
              disabled={loading}
            >
              {races.map((race, index) => (
                <option key={index} value={race}>
                  {race}
                </option>
              ))}
            </select>
          </div>
          <button onClick={handlePurchase} disabled={loading}>
            {loading ? 'Purchasing...' : 'Purchase'}
          </button>
        </>
      ) : (
        <p>{message}</p>
      )}
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default CharacterSale;
