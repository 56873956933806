import React from 'react';
import { FaTelegramPlane, FaDiscord, FaTwitter } from 'react-icons/fa';
import '../styles/Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="social-icons">
        <a href="https://t.me/protoniaapp" target="_blank" rel="noopener noreferrer">
          <FaTelegramPlane />
        </a>
        <a href="https://discord.gg/B56ek5AD" target="_blank" rel="noopener noreferrer">
          <FaDiscord />
        </a>
        <a href="https://twitter.com/protonianet" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
      </div>
      <p>&copy; 2024 Protonia. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
